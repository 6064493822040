@use "sass:math";

@import 'Variables';
// @import 'Animations';
@import 'Mixins';
@import 'Type';
@import 'Buttons';
@import 'Cards';
@import 'Layout';

.select {
  @include select();
}

.mainGrounds {
  background-color: var(--brand-blue);
  padding-bottom: 6rem;
  @media (min-width: $xl) {
    padding-bottom: 10rem;
  }
}

.groundsGameInfo {
  color: white;
  text-align: center;
  padding-bottom: 2.4rem;
  margin-top: -2rem;
}

.groundsMapContainer {
  background-color: var(--body-color);
  padding-top: 4rem;
  padding-bottom: 4rem;
  width: 100%;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 2.6rem;
  @media (min-width: $xl) {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
}

.groundsMap {
  width: 100%;
  display: flex;
  justify-content: center;
}

.pageTop {
  padding-top: 2rem;
  padding-bottom: 2rem;
  @media (min-width: $lg) {
    padding-top: 5rem;
  }
}

.fontPageTitle {
  color: var(--heading-blue);
  font-size: 4.8rem;
  @media (min-width: $lg) {
    font-size: 6.4rem;
  }
}

.groundsHeader {
  align-items: center;
  .fontPageTitle {
    color: white;
  }
}

.backButton {
  color: white;
}

.groundsHeader {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .groundsHeaderDownload {
    @media (max-width: 1200px) {
      display: none;
    }
  }
}

.groundsMapDownload {
  padding-top: 2rem;
  display: flex;
  justify-content: center;
  @media (min-width: 1200px) {
    display: none;
  }
}


.fixture {
  border: 1px solid #D7D7D7;
  padding: 1.6rem;
  background-color: white;
  display: flex;
  flex-wrap: wrap;
  max-width: 1310px;
  margin-left: auto;
  margin-right: auto;
  @media (min-width: $lg) {
    padding-left: 2.4rem;
    padding-right: 2.4rem;
  }
  &:first-child {
    border-top: 0;
  }
  &Teams {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-left: -1rem;
    margin-right: -1rem;
    color: white;
    @media (min-width: $lg) {
      flex: 0 0 45%;
      max-width: 45%;
    }
  }
  &Team {
    flex: 0 0 calc(50% - 32px);
    max-width: calc(50% - 32px);
    align-self: center;
    display: flex;
    align-items: center;
    padding-left: 1rem;
    padding-right: 1rem;
    @media (min-width: $lg) {
      flex: 0 0 calc(50% - 24px);
      max-width: calc(50% - 24px);
    }
    @media (min-width: $md) {
      justify-content: center;
    }
    + .fBodyStrong {
      margin-left: 1rem;
      margin-right: 1rem;
    }
  }
  &TeamLogo {
    width: 4.2rem;
    height: 4.2rem;
    flex-shrink: 0;
    @media (min-width: $xl) {
      width: 6.8rem;
      height: 6.8rem;
    }
  }
  &TeamTitle {
    padding-left: .8rem;
    padding-right: .8rem;
    text-align: center;
    @media (min-width: $md) {
      padding-left: 1.6rem;
      padding-right: 1.6rem;
    }
  }
  &Info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 0 0 100%;
    max-width: 100%;
    
    @media (max-width: $lg) {
      padding-top: 1rem;
      display: none;
      flex-wrap: wrap;
      justify-content: space-evenly;
    }
    @media (min-width: $lg) {
      flex: 0 0 55%;
      max-width: 55%;
    }
    @media (max-width: 680px) {
      justify-content: center;
    }
  }
  .infoDetails {
    flex: 0 0 24rem;
    max-width: 24rem;
    @media (max-width: $lg) {
      padding-top: 1rem;
      flex: 0 0 206px;
      max-width: 206px;
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }
  .infoLink {
    @media (max-width: $lg) {
      flex: 0 0 206px;
      max-width: 206px;
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }
  &Date,
  &Loaction {
    display: block;
    text-align: center;
  }
  &.fixtureIsOpen {
    .fixtureInfo {
      display: flex;
    }
    .fixtureToggle {
      transform: rotate(-180deg);
    }
  }
}

.fBody {
  font-size: 1.4rem;
  font-family: var(--font-regular);
  &Strong {
    font-size: 1.4rem;
    font-family: var(--font-semibold);
  }
}

// ----------------------
// Still to check
// ----------------------
.section {
  padding-top: 4rem;
  padding-bottom: 4rem;
  &--dark {
    background-color: var(--dark-theme-bg);
    color: white;
    .ctaTitle {
      text-transform: uppercase;
      max-width: 66.666666%;
      line-height: math.div(92, 80);
      position: relative;
      top: 0.085em;
      @media (max-width: $md) {
        font-size: 4.2rem;
      }
    }
  }
  &--blue {
    background-color: var(--button-3-brand);
    color: white;
  }
  &--center {
    text-align: center;
    .mainContainer {
      justify-content: center;
      flex-direction: column;
      align-items: center;
    }
  }
  &--light {
    padding-top: 12rem;
    padding-bottom: 12rem;
  }
}

.section {
  padding-top: 4rem;
  padding-bottom: 4rem;
  &--dark {
    background-color: var(--dark-theme-bg);
    color: white;
    .ctaTitle {
      text-transform: uppercase;
      max-width: 66.666666%;
      line-height: math.div(92, 80);
    }
  }
  &--blue {
    background-color: var(--button-3-brand);
    color: white;
  }
  &--center {
    text-align: center;
    .mainContainer {
      justify-content: center;
      flex-direction: column;
      align-items: center;
    }
  }
  &--light {
    padding-top: 6rem;
    padding-bottom: 6rem;
    @media (min-width: $md) {
      padding-top: 8rem;
      padding-bottom: 8rem;
    }
    @media (min-width: $xl) {
      padding-top: 10rem;
      padding-bottom: 10rem;
    }
    @media (min-width: $xxl) {
      padding-top: 12rem;
      padding-bottom: 12rem;
    }
  }
}

.cta {
  position: relative;
  padding-top: 7.2rem;
  padding-bottom: 7.2rem;
  // overflow: hidden;
  &Content {
    position: relative;
    z-index: 1000;
    margin-top: -0.75em;
    p {
      @extend .font1;
      + .button {
        margin-top: 2.4rem;
      }
    }
  }
  .mainContainer {
    display: flex;
    position: relative;
    z-index: 1000;
  }
  &Title {
    font-size: 6.4rem;
    font-family: var(--font-condensed-semibold);
    margin-top: -0.35em;
    line-height: math.div(94, 64);
    text-transform: uppercase;
  }
}

// CTA
.cta {
  position: relative;
  &--column {
    .mainContainer {
      flex-direction: column;
    }
  }
  &Actions {
    padding-top: 4rem;
    display: flex;
    align-items: center;
    margin-left: -1rem;
  }
  &Action {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  // Possibly can remove
  &Image {
    position: relative;
    width: 100%;

    &:before {
      width: 100%;
      content: '';
      display: block;
      padding-bottom: 40%;
    }
    img {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      object-fit: cover;
      object-position: center center;
      max-width: 100%;
      max-height: 100%;
    }
  }
}

.section.undecidedCta {
  padding-top: 0;
  padding-bottom: 0;
  .ctaText {
    max-width: 54rem;
    @media (max-width: $lg) {
      font-size: 16px;
      line-height: math.div(18, 16);
      letter-spacing: 0.02em;
    }
  }
  .ctaTitle {
    @media (max-width: $lg) {
      line-height: .8;
    }
  }
}

.undecidedCtaContainer {
  position: relative;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  padding-top: 4rem;
  padding-bottom: 4rem;
  @media (min-width: $lg) {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
  &:before {
    position: absolute;
    top: 0;
    left: auto;
    bottom: 0;
    content: '';
    display: block;
    width: 100vw;
    background-color: #000000;
    right: 100%;
  }
  .mainContainer {
    width: 100%;
    display: block;
  }
  .ctaGraphic {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    max-width: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    @media (max-width: $md) {
      max-width: 40%;
    }
    img {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      height: 100%;
      width: auto;
    }
    svg {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      height: 100%;
      width: auto;
    }
  }
  .mobileText {
    @media (min-width: $lg) {
      display: none;
    }
  }
  .desktopText {
    display: none;
    @media (min-width: $lg) {
      display: block;
    }
  }
  .col6 {
    display: flex;
    height: 100%;
    align-items: center;
    @media (max-width: $md) {
      flex: 0 0 40%;
      max-width: 40%;
      & + .col6 {
        flex: 0 0 60%;
        max-width: 60%;
      }
    }
  }
  .row {
    align-items: center;
  }
}

.sectionTitle {
  @include font-heading();
}

.section.registerCta {
  padding-top: 0;
  .ctaTitle {
    padding-top: 2rem;
    margin-bottom: 2rem;
  }
  .ctaText {
    max-width: 44rem;
    margin-left: auto;
    margin-right: auto;
  }
  .contactCtaAngleBottom {
    display: none;
  }
  & + .contactCtaContainer {
    display: none;
  }
}

.registerCtaGreyBlue {
  section.registerCta {
    .contactCtaAngleBottom {
      display: block;
    }
    & + .contactCtaContainer {
      display: block;
    }
  }
}

.section.contactCta {
  padding-top: 0;
  padding-bottom: 0;
  .ctaTitle {
    padding-top: 2.4rem;
    margin-bottom: 2rem;
    @media (max-width: $lg) {
      margin-bottom: 2.6rem;
      font-size: 3.3rem;
      line-height: math.div(50, 33);
    }
  }
  .ctaText {
    max-width: 44rem;
    margin-left: auto;
    margin-right: auto;
    @media (max-width: $lg) {
      @include font-sm();
    }
  }
  @media (max-width: $lg) {
    .button {
      width: 26rem;
    }
  }
}

.contactCtaContainer {
  position: relative;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 5rem;
}

.contactCtaAngle {
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: auto;
}

.contactCtaAngleBottom {
  width: 100%;
  height: auto;
}

.contactCtaAngleSpacer {
  width: 100%;
  height: auto;
  path {
    fill: rgba( 0, 0, 0, 0);
  }
}

.registerCtaGreyBlue {
  .mainContainer {
    position: relative;
    top: 2rem;
    top: clamp(0rem, 2vw, 4rem);
  }

  .contactCtaAngle {
    path {
      fill: var(--dirty-grey);
    }
  }

  .contactCtaContainer {
    padding-bottom: 0;
  }

  .contactCtaAngleBottom {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: calc(100% + 4rem);
    path {
      fill: var(--brand-blue);
    }
  }
}

.section.hero {
  position: relative;
  background-color: var(--button-3-brand);
  color: white;
  padding-top: 0;
  padding-bottom: 0;
  overflow: hidden;
  padding-bottom: 7rem;
  @media (min-width: $md) {
    padding-bottom: 0;
  }
  .heroImageMobile {
    @media (min-width: $md) {
      display: none;
    }
  }
  .heroImageDesktop {
    display: none;
    @media (min-width: $md) {
      display: block;
    }
  }
  .heroInner {
    position: relative;
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
    &:before {
      position: absolute;
      content: '';
      display: block;
      width: 100vw;
      height: 100%;
      right: 0;
      top: 0;
      bottom: 0;
      background-color: var(--sky-blue);
      left: 100%;
    }
    .heroImageAfter {
      position: absolute;
      content: '';
      display: block;
      width: 100vw;
      height: 100%;
      right: 0;
      top: 0;
      bottom: 0;
      background-color: pink;
      left: 100%;
      width: 720px;
      height: 627px;
      img {
        @include background-image();
      }
    }
  }
  .heroImage {
    position: relative;
    width: 100%;

    &:before {
      width: 100%;
      content: '';
      display: block;
      padding-bottom: 109.3%;
      @media (min-width: $md) {
        padding-bottom: 43.5425%;
      }
    }
    img {
      @include background-image();
    }
  }
  .heroTitle {
    font-family: var(--font-condensed-semibold);
    font-size: 4.2rem;
    letter-spacing: 0.015em;
    line-height: math.div(112, 110);  
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    text-transform: uppercase;
    margin-top: 0 !important;
    max-width: 240rem;
    @media (max-width: 768px) {
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      position: absolute;
      top: 2rem;
      top: clamp(2.2rem, 10vw, 10rem);
      left: 0;
      right: 0;
    }
    @media (min-width: $smm) {
      font-size: 6rem;
      max-width: 28rem;
    }
    @media (min-width: $md) {
      max-width: none;
    }
    @media (min-width: $lg) {
      font-size: 9rem;
    }
    @media (min-width: $xl) {
      font-size: 11rem;
    }
  }
  .heroActions {
    display: flex;
    align-items: center;
    margin-left: -0.4rem;
    margin-right: -0.4rem;
    padding-top: 4rem;
    @media (max-width: 768px) {
      position: absolute;
      left: 0;
      bottom: -6.2rem;
      justify-content: center;
      width: calc(100% + 0.8rem);
    }
    @media (min-width: $md) {
      margin-left: -1rem;
      margin-right: -1rem;
    }
  }
  .heroAction {
    padding-left: 0.4rem;
    padding-right: 0.4rem;
    @media (min-width: $md) {
      padding-left: 1rem;
      padding-right: 1rem;
    }
    .button {
      @media (max-width: $smm) {
        font-size: 1.8rem;
        line-height: math.div(21, 18);
        letter-spacing: 0.015em;
        font-family: var(--font-semibold);
        padding: 1.6rem 2rem;
      }
    }
  }
  .mainContainer {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
  }
  .heroContentCol {
    flex: 0 0 100%;
    max-width: 100%;
    @media (min-width: $md) {
      flex: 0 0 68%;
      max-width: 68%;
    }
    @media (min-width: 820px) {
      flex: 0 0 65%;
      max-width: 65%;
    }
    @media (min-width: $lg) {
      flex: 0 0 60%;
      max-width: 60%;
    }
    @media (min-width: $xl) {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
}

// Header
.header,
.headerClub {
  width: 100%;
  height: 6rem;
  position: relative;
  z-index: 9999;
  background-color: white;
  top: 0;
  left: 0;
  .mainContainer {
    height: 6rem;
    background-color: var(--body-bg);
    color: var(--body-color);
    display: flex;
    align-items: center; 
  }
  svg:not(.spriteIcon) {
    width: 54px;
    height: 57px;
    margin-bottom: -8.6rem;
    @media (min-width: $lg) {
      width: 124px;
      height: 131px;
      margin-bottom: -8.6rem;
    }
  }
  .button {
    display: inline-flex;
    align-items: center;
    color: var(--button-3-brand);
    margin-left: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
    text-transform: uppercase;
    &:first-child {
      margin-left: 1.6rem;
    }
    &:first-child {
      margin-left: 1.8rem;
    }
    @media (min-width: $smm) {
      margin-left: 3rem;
      &:first-child {
        margin-left: 2.2rem;
      }
    }
    svg {
      margin-bottom: -0.1rem;
    }
  }
  .logoLink {
    top: -6.4rem;
    margin-left: -1rem;
    @media (min-width: $lg) {
      top: -0.2rem;
      margin-left: -1.6rem;
    }
  }
  @media (min-width: $lg) {
    height: 9rem;
    .mainContainer {
      height: 9rem;
    }
  }
}

.logoLink {
  position: relative;
  display: block;
}

.headerClub {
  background-color: var(--brand-blue);
  height: 6rem;
  .button {
    color: white;
  }
  .mainContainer {
    height: 6rem;
  }
  .logoLink {
    top: -6.4rem;
  }
  svg:not(.spriteIcon) {
    width: 54px;
    height: 57px;
    margin-bottom: -8.6rem;
  }
}

.infoItems {
  display: flex;
  padding-top: 2rem;
  padding-bottom: 2rem;
  flex-wrap: wrap;
}

.infoItem {
  display: flex;
  align-items: center;
  padding-top: .4rem;
  padding-right: 2rem;
  padding-bottom: .4rem;
  white-space: nowrap;
  @media (max-width: $smm) {
    flex: 0 0 100%;
    max-width: 100%;
  }
  @media (min-width: $smm) {
    border-right: 1px solid var(--body-color);
    margin-right: 2rem;
  }
  &:last-child {
    margin-right: 0;
    border: 0;
  }
  &Icon {
    display: flex;
    padding-right: 0.8rem;
  }
  &Text {
    @include font-sm();
    line-height: 1;
  }
}

// Cjheck if needed 
.filterContainer {
  padding-bottom: 2.4rem;
  @media (min-width: $md) {
    padding-bottom: 5.6rem;
  }
}

.filterMobileText {
  @media (min-width: $md) {
    display: none;
  }
}

.filter {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  padding: 1rem 1.6rem;
  margin-top: 3.2rem;
  margin-bottom: 2rem;
  border-radius: var(--border-radius);
  background-color: var(--dirty-grey);
  position: relative;
  @media (min-width: $md) {
    padding: 1.2rem 1.6rem 1.2rem 9.6rem;
    margin-top: 7.8rem;
  }
  &Item {
    width: 100%;
    padding: 0.4rem 0;
    @media (min-width: $md) {
      padding: 0.4rem 0.8rem;
    }
    &:nth-child(2) {
      min-width: calc(((100% - 16rem) / 4));
    }
    &:nth-child(3) {
      min-width: calc(((100% - 16rem) / 4));
    }
    &:nth-child(4) {
      min-width: calc(((100% - 16rem) / 4));
    }
    &:nth-child(5) {
      min-width: calc(((100% - 16rem) / 4));
    }
    &:nth-child(6) {
      min-width: 14.8rem;
    }
    .select {
      width: 100%;
      padding: 1rem 1.4rem;
    }
    @media (min-width: $md) {
      width: auto;
      .select {
        width: auto;
        min-width: 100%;
      }
    }
  }
  &Label {
    display: flex;
    align-items: center;
    padding-right: 0.8rem;
    font-family: var(--font-regular);
    padding-bottom: 0.8rem;
    padding-right: 3.4rem !important;
    background-repeat: no-repeat;
    background-position: center right 0.8rem;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M6.175 7.15801L5 8.33301L10 13.333L15 8.33301L13.825 7.15801L10 10.9747L6.175 7.15801Z' fill='%23222222'/%3e%3c/svg%3e");
    cursor: pointer;
    width: 100%;
    padding-top: 0.8rem;
    @media (min-width: $md) {
      position: absolute;
      top: 2.3rem;
      left: 1.6rem;
      padding-bottom: 0;
      width: auto;
      background-image: none;
      padding-top: 0;
    }
    &Icon {
      display: block;
      margin-bottom: -0.3rem;
    }
    &Text {
      margin-left: 0.8rem;
      color: black;
      font-size: 1.6rem;
    }
  }
}

.fontHeading.clubsTitle {
  line-height: 1;
  padding-bottom: 0.5rem;
}

.filteredAddress {
  font-family: var(--font-regular);
  font-size: 4rem;
  line-height: 1.05;
  padding-bottom: 1rem;
  letter-spacing: 0.01em;
  font-weight: normal;
  font-size: 2.4rem;
  line-height: 1;
  @media (min-width: $md) {
    font-size: 2.8rem;
  }
  @media (min-width: $xl) {
    font-size: 4rem;
  }
}

.filter {
  &:not(.filterOpen) {
    @media (max-width: $md) {
      .filterItem {
        height: 0;
        overflow: hidden;
        padding-top: 0;
        padding-bottom: 0;
      }
    }
  }
}

.filterClearButton {
  padding: 1.1rem 1.4rem;
  border: 0;
  outline: 0;
  border-radius: var(--border-radius);
  background-color: var(--dirt-grey);
  background-position: center left 1.2rem;
  background-repeat: no-repeat;
  font-family: var(--font-regular);
  font-size: 1.6rem;
  cursor: pointer;
  width: 100%;
  justify-content: center;
  @media (min-width: $md) {
    padding: 1.1rem 1.4rem 1.1rem 3.8rem;
    width: auto;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z' fill='black'/%3e%3c/svg%3e ");
  }
}

.filterTags {
  display: flex;
  flex-wrap: wrap;
}

.filterTag {
  display: block;
  padding: 0.4rem 3.8rem 0.4rem 0.8rem;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z' fill='black'/%3e%3c/svg%3e ");
  background-position: center right 1.2rem;
  background-repeat: no-repeat;
  text-transform: uppercase;
  border: 1px solid #D1D1D1;
  background-color: var(--dirty-grey);
  margin-right: 2.4rem;
  border-radius: var(--border-radius);
  color: black;
  @include font-sm();
  white-space: nowrap;
  cursor: pointer;
  margin-top: 0.4rem;
  margin-bottom: 0.4rem;
  &:last-child {
    margin-right: 0;
  }
}

.noResults {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
  padding-top: 3.2rem;
  padding-bottom: 3.2rem;
  p {
    max-width: 650px;
    margin-left: auto;
    margin-right: auto;
    a {
      color: var(--sky-blue);
      text-decoration: underline;
    }
  }
}

// Contact
.clubContactSection {
  padding-top: 6.4rem;
  padding-bottom: 6.4rem;
}

.clubContactActions {
  padding-top: 3.2rem;
  display: flex;
  flex-wrap: wrap;
  margin-left: -1rem;
  margin-right: -1rem;
}
.clubContactAction {
  padding-left: 1rem;
  padding-right: 1rem;
  @media (max-width: $smm) {
    flex: 0 0 100%;
    max-width: 100%;
    .button {
      width: 23rem;
    }
    & + .clubContactAction {
      margin-top: 1rem;
    }
  }
}

.clubMapContainer,
.contactMapContainer {
  position: relative;
  width: 100%;
}

.googleMaps {
  position: 'absolute';
  top: 0;
}

.contactMapContainer {
  margin-top: 4rem;
}

.clubMapCol {
  @include col();
  flex: 0 0 100%;
  max-width: 100%;
  padding-top: 3.2rem;
  @media (min-width: $md) {
    flex: 0 0 50%;
    max-width: 50%;
    padding-top: 0;
  }
}

.clubContactTitle {
  @include font-heading();
  line-height: 1.1;
  text-transform: uppercase;
  margin-bottom: 0.25em;
  @media (max-width: $xl) {
    font-size: 4.2rem;
    line-height: 1.2;
  }
  @media (max-width: $smm) {
    font-size: 3.3rem;
    line-height: 1.2;
  }
}

.contactItem {
  display: flex;
  align-items: flex-start;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  &Icon {
    position: relative;
    bottom: -0.3rem;
    display: block;
    padding-right: 1rem;
  }
  &Text {
    @include font-sm();
    padding-top: 0.2rem;
  }
}

.formLayout {
  padding-top: 4rem;
  padding-bottom: 4rem;
  @media (min-width: $md) {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
  @media (min-width: $lg) {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
  .row {
    justify-content: space-between;
  }
}

.formHero {
  background-color: var(--brand-blue);
  position: relative;
  height: 24rem;
  @media(min-width: $md) {
    height: 36.1rem;
  }
  &Content {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
  }
  &Image {
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    img {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-size: cover;
      object-fit: cover;
      object-position: center center;
      width: 100%;
      height: 100%;
    }
  }
}

.formContactTitle {
  @include font-heading-sm();
}

.rte {
  padding-top: 4rem;
  padding-bottom: 4rem;
  h1 {
    @include font-heading();
    text-align: center;
    line-height: 1.1;
    margin-bottom: 0.5em;
    @media (max-width: $lg) {
      font-size: 4.8rem;
    }
    span {
      white-space: nowrap;
    }
  }
  h2 {
    @include font-heading-3();
    text-align: center;
    margin-bottom: 1em;
  }
  h2 + h3 {
    margin-top: -1em;
    margin-bottom: 4rem;
  }
  h3 {
    @include font-heading-sm();
    text-align: center;
  }
  a {text-decoration: underline;}
  ol {
    padding-left: 2rem;
    li {
      margin-bottom: 3.2rem;
    }
    ul:not(.styledList) {
      list-style-type: none;
      li {
        margin-top: 0.8rem;
        margin-bottom: 0.8rem;
      }
    }
    ul.styledList {
      list-style-type: disc;
      padding-left: 2rem;
      padding-top: 1rem;
      padding-bottom: 2rem;
      &:last-child() {
        padding-bottom: 0;
      }
      li {
        margin-top: 0.8rem;
        margin-bottom: 0.8rem;
      }
    }
  }
  li {
    p + p {
      margin-top: 1.6rem;
    }
  }
}

.contentTile {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  p {
    flex-grow: 1;
    padding-bottom: 2.5rem;
    line-height: math.div(48, 32);
  }
  &Title {
    font-weight: normal;
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 48px;
    @media (min-width: $md) {
      margin-top: 1.6rem;
      margin-bottom: 3rem;
    }
    @media (max-width: $md) {
      font-size: 2.4rem;
    }
  }
  &Header {
    @media (max-width: $md) {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }
  svg {
    display: block;
    margin-right: 1rem;
  }
}