// Responsive sizes
$sm : 400px;
$smm : 540px;
$sm-md  : 744px;
$md : 768px;
$lg-md  : 856px;
$lg : 992px;
$xl : 1200px;
$xxl : 1600px;
$xxxl : 2000px;

:root {
    --font-regular: 'founders-grotesk-regular';
    --font-regular-italic: 'founders-grotesk-regular-italic';
    --font-medium: 'founders-grotesk-medium';
    --font-semibold: 'founders-grotesk-semibold';
    --font-condensed-medium: 'founders-grotesk-x-condensed-medium';
    --font-condensed-semibold: 'founders-grotesk-x-condensed-semibold';
}