@import 'Mixins';

.button {
  @include button();
  position: relative;
  text-align: center;
  transition: all var(--button-transition-speed) ease;
  justify-content: center;

  &--1 {
    border: 1px solid var(--button-1-brand-bg);
    color: var(--button-1-brand);
    background-color: var(--button-1-brand-bg);
    box-shadow: 0px 0px 8px rgba(227, 0, 31, 0);
  }

  &--new1 {
    border: 1px solid var(--button-1-brand-bg);
    color: var(--button-1-brand);
    background-color: var(--button-1-brand-bg);
    box-shadow: 0px 0px 8px rgba(227, 0, 31, 0);
    padding: 1.6rem 3.2rem;
    font-size: 2.1rem;

    @media (max-width: $smm) {
      font-size: 1.6rem;
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background-color: #720211;
        border-color: #720211;
      }
    }
  }

  &--new1-white {
    background-color: #fff;
    color: #222222;
    border: none;
  }

  &--2 {
    background-color: var(--button-2-brand-bg);
    border: 1px solid var(--button-2-brand);
    color: var(--button-2-brand);
    box-shadow: 0px 0px 8px rgba(227, 0, 31, 0);
  }

  &--new2 {
    border: 2px solid var(--white);
    color: var(--white);
    box-shadow: 0px 0px 8px rgba(227, 0, 31, 0);
    padding: 1.3rem 3.2rem;
    font-size: 2.1rem;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;

    >span {
      font-size: 1.6rem;
      color: var(--neturals-black);
      background-color: var(--white);
      padding: 0.5rem 0.4rem;
      margin-left: 0.3rem;
      border-radius: 0.5rem;
      line-height: 90%;
    }

    @media (max-width: $smm) {
      font-size: 1.6rem;
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background: rgba(255, 255, 255, 0.16);
        /* Neutrals/White */
        border: 2px solid #FFFFFF;
        backdrop-filter: blur(2px);
      }
    }
  }

  &--3 {
    border: 1px solid var(--button-3-brand);
    color: var(--button-3-brand);
    background-color: var(--button-3-brand-bg);
    font-size: 1.8rem;
    padding: 0.8rem 1.6rem;
  }

  &--4 {
    border: 1px solid var(--button-4-brand-bg);
    color: var(--button-4-brand);
    background-color: var(--button-4-brand-bg);
    font-size: 1.6rem;
    padding: 1.1rem 1.6rem;

    @media (min-width: 1023px) {
      font-size: 2.2rem;
      padding: 1.4rem 1.6rem;
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background-color: var(--action-blue);
        border-color: var(--action-blue);
      }
    }

    &:active {
      background-color: var(--brand-blue);
      border-color: var(--brand-blue);
    }

    .buttonIcon {
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        @media (max-width: 1023px) {
          width: 1.4rem;
          height: 1.4rem;
        }
      }
    }
  }

  &--5 {
    border: 2px solid #fff;
    color: #fff;
    background-color: transparent;
    font-size: 1.6rem;
    padding: 0.8rem 3rem;
    font-family: var(--font-regular);
  }

  &--6 {
    color: #fff;
    background-color: #E3001F;
    font-size: 1.6rem;
    padding: 0.8rem 3rem;
    font-family: var(--font-regular);
  }

  &--1,
  &--2 {
    @media (hover: hover) and (pointer: fine) {
      &:hover {
        @include main-button-hover();
      }
    }
  }

  &--icon {
    width: var(--buton-icon-size);
    height: var(--button-icon-size);
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.4rem;
    height: 2.4rem;
  }

  &--link {
    font-size: 1.6rem;
    font-family: var(--font-semibold);
    padding: 0;
    display: inline-flex;
    white-space: nowrap;
  }

  &--text-link {
    font-size: 1.6rem;
    font-family: var(--font-regular);
    padding: 0;
    display: inline-flex;
    white-space: nowrap;
    color: var(--action-blue-text);
    text-decoration: underline;
    border: 0;
    background-color: rgba(0, 0, 0, 0);
    display: inline;
    font-size: inherit;

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        color: var(--action-blue);
      }
    }
  }

  &--none {
    padding: 0;
    display: inline-flex;
    font-family: inherit;
    font-size: inherit;
  }

  &--sm {
    font-size: 1.6rem;
    padding: 0.8rem 1.6rem;
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

// TODO @Dario  
.buttonText {
  font-size: 0;
}

.backButton,
.forwardButton {
  @include button();
  border: 0;
  outline: 0;
  color: var(--brand-blue);
  display: inline-flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0);
  padding-left: 0 !important;
  padding-right: 0 !important;

  .buttonText {
    font-size: 1.6rem;
  }

  .buttonIcon {
    margin-right: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.forwardButton {
  .buttonIcon {
    margin-right: 0;
    margin-left: 1rem;
    transform: rotate(-180deg);
  }
}

@mixin smallButton() {
  padding: 1.2rem 1.6rem;
  font-size: 1.6rem;
  line-height: 1rem;
}

@mixin mediumButton() {
  padding: 1.6rem 2.4rem;
  font-size: 2.1rem;
  line-height: 13px;
}

@mixin largeButton() {
  padding: 2.4rem 3.2rem;
  font-size: 4.4rem;
  line-height: 28px;
}


@mixin commonHover($color: rgba(255, 255, 255, 0.3)) {
  &:hover {
    background: linear-gradient(0deg, $color, $color), var(--primaryColor);
  }
}

@mixin commonYearStyles {
  padding: 0.5rem;
  border-radius: 0.4rem;
  margin-left: 0.3rem;
  background-color: #000000;
  color: #FFFFFF;

  &:hover {
    background-color: #000000;
    color: #FFFFFF;
  }
}

@mixin buttonVariant($bgColor, $textColor, $hoverColor) {
  @include n_button();
  background-color: $bgColor;
  color: $textColor;
  text-transform: uppercase;
  display: flex;
  align-items: center;

  @media (hover: hover) and (pointer: fine) {
    @include commonHover($hoverColor);
  }

  &Year {
    @include commonYearStyles;
  }

  &YearText {
    color: #FFFFFF;
  }

  &Medium {
    @include mediumButton();
    padding: 1.4rem 2.4rem;
  }

  &Medium1 {
    @include mediumButton();
    padding: 1.75rem 2.4rem;
  }
}

.buttonPrimary {
  @include n_button();
  background-color: #E3001F;
  color: #FFFFFF;
  text-transform: uppercase;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background-color: #9F0016;
    }
  }

  &:focus,
  &:active {
    outline: 4px solid #FF667B;
    background-color: #E3001F;
  }

  &:disabled {
    background-color: #F2CBD0;
  }

  &Small {
    @include smallButton();
  }

  &Medium {
    @include mediumButton();
  }

  &Large {
    @include largeButton();
  }


}

.buttonSecondary {
  @include n_button();
  background-color: #FFFFFF;
  color: #222222;
  text-transform: uppercase;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), #FFFFFF;
    }
  }

  &:focus,
  &:active {
    outline: 4px solid #FFFFFF;
    background-color: #FFFFFF;
  }

  &:disabled {
    background-color: #F2CBD0;
  }

  &Small {
    @include smallButton();
  }

  &Medium {
    @include mediumButton();
  }

  &Large {
    @include largeButton();
  }


}

.buttonTertiary {
  @include n_button();
  background-color: transparent;
  color: #FFFFFF;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  border: 2px solid #FFFFFF;

  &:hover {
    background: #FFFFFF;
    color: #000000;

    .buttonTertiary {
      &Year {
        background-color: #000000;
        color: #FFFFFF;
      }
    }
  }

  &Year {
    padding: 0.5rem;
    border-radius: 0.4rem;
    margin-left: 0.3rem;
    background-color: #FFFFFF;
    color: #000000;
  }

  &YearText {
    color: #FFFFFF;
  }

  &Medium {
    @include mediumButton();
    padding: 1.05rem 2.4rem;
  }

  &NoSubText {
    padding: 1.6rem 2.4rem !important;
  } 
}

.buttonDark {
  @include buttonVariant(var(--primaryColor), #000000, rgba(255, 255, 255, 0.3));
}

.buttonLight {
  @include buttonVariant(var(--primaryColor), #FFFFFF, rgba(0, 0, 0, 0.3));
}