:root {
  --max-width               : 1600px;
  --max-width-sm            : 904px;
  --max-width-md            : 1133px;
  --max-width-lg            : 1440px;
  --border-radius           : 0.4rem;
  
  --font: 'sans serif';
  --root-font-size          : 10px;
  --body-font-size          : 2rem;
  --base-font               : 'sans-serif';

  --body-color              : #222222;
  --bg-color                : #ffffff;
  --white                : #ffffff;
  --light-border            : #DDDDDD;
  --light-grey              : #999999;
  --dirty-grey              : #F7F7FA;
  --dirt-grey               : #D7D7D7;
  --header-bg               : rgba(26, 21, 36, 0.95);
  --neturals-black          : #222222;
  --gold-tint-10            : #FFF7E5;


  // Colours
  --dark-theme-bg           : var(--body-color);
  --brand-blue              : #002480;
  --brand-red               : #E3001F;
  --sky-blue                : #1C70FF;
  --heading-blue            : #001957;
  --action-blue             : #2D5BD2;
  --action-blue-text        : #5080FA;

  // buttons
  --button-transition-speed : 0.5s;
  --button-border-radius    : 0.4rem;
  --button-border-with      : 0.1rem;
  --button-padding-y-sm     : 1.1rem;
  --button-padding-x-sm     : 2rem;
  --button-padding-y        : 1.5rem;
  --button-padding-x        : 3rem;
  --button-1-brand          : white;
  --button-1-brand-bg       : rgba(227, 0, 31, 1);
  --button-2-brand          : rgba(227, 0, 31, 1);
  --button-2-brand-bg       : white;
  --button-3-brand          : var(--brand-blue);
  --button-3-brand-bg       : white;
  --button-4-brand          : white;
  --button-4-brand-bg       : var(--action-blue-text);

  --main-button-hover-bg    : #B60019;

  // --input-border            : #D7D7D7;
  --input-border            : #999999;
  
  --input-placeholder       : var(--light-grey);

  // Club Games
  --game-disabled           : var(--dirt-grey);

  // Footer 
  --footer-bg               : var(--body-color);
  --footer-bg-news          : #1A1524;
  --footer-color            : white;
  --gutter                  : 1.6rem; // half of actual
  --header-height           : 6rem;
}

@media (min-width: 992px) {
  :root {
    --header-height           : 10.3rem;
  }
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
html {
  font-size: var(--root-font-size);
}

main {
  width: 100%;
}

img {
  max-width: 100%;
  height: auto;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
}


body {
  color: var(--body-color);
  background-color: var(--bg-color);
  font-size: var(--body-font-size);
  font-family: var(--font-regular);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  &.news-body  {
    background-color: #0F0F0F;
  }
}

a {
  color: inherit;
  text-decoration: none;
}


/* Enable hover only on non-touch devices */
@media (hover: hover) and (pointer: fine) {
  .something {
    // Here
  }
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: var(--input-placeholder);
}
::-moz-placeholder { /* Firefox 19+ */
  color: var(--input-placeholder);
}
:-ms-input-placeholder { /* IE 10+ */
  color: var(--input-placeholder);
}
:-moz-placeholder { /* Firefox 18- */
  color: var(--input-placeholder);
  opacity: 1;
}

option[value=""] {
  display: none;
}

// Open Date picker on input box click 
input.picker[type="date"] {
  position: relative;
}

input.picker[type="date"]::-webkit-calendar-picker-indicator {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  color: transparent;
  background: transparent;
}

select:disabled {
  pointer-events: none;
  opacity: .4;
}