@use "sass:math";

@import 'Variables';
// @import 'Animations';
@import 'Mixins';
@import 'Type';
@import 'Buttons';
@import 'Cards';
@import 'Layout';

.menuButton {
  color: var(--white);
  border: 0;
  align-items: center;
  position: absolute;
  top: 50%;
  right: 2rem;
  transform: translateY(-50%);
  font-family: var(--font-semibold);
  text-transform: uppercase;
  background-color: rgba(0, 0, 0, 0);
  font-size: 1.6rem;
  cursor: pointer;
  z-index: 1000;
  display: none;

  @media (max-width: $md) {
    display: flex;
  }
}

.menuBurger {
  display: flex;
  flex-direction: column;
  width: 2.2rem;
  height: 1.6rem;
  align-items: center;
  justify-content: space-between;
  margin-right: .9rem;
  margin-bottom: -2px;

  span {
    display: block;
    border-radius: .1rem;
    width: 100%;
    border-top: .2rem solid var(--white);
  }
}

.headerLinks {
  transition: transform 0.5s ease-in-out;
  color: var(--brand-blue);
  width: 100%;
  margin-left: 10rem;

  @media (max-width: $lg) {
    margin-left: 0rem;
  }

  @media (max-width: $md) {
    color: white;
    position: fixed;
    right: 0;
    top: 6rem;
    width: 100%;
    bottom: 0;
    transform: translateX(100%);
    background-color: var(--brand-blue);
    padding-top: 4rem;
    display: flex;
    flex-direction: column;
    overflow: auto;
  }

  &Nav {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    margin-right: 4rem;

    @media (max-width: $md) {
      display: block;

      .button.button--link {
        display: flex;
        justify-content: flex-start;
        width: auto;
        margin-left: 0 !important;
        padding-left: 2rem;
        padding-right: 2rem;
        margin-top: 2rem;
        margin-bottom: 2rem;
        font-family: var(--font-condensed-semibold);
        font-size: 4.6rem;
      }
    }
  }

  .menuNavigationOpen & {
    transform: translateX(0);
  }
}

.headerClub {
  .headerLinks {
    @media (max-width: $md) {
      background-color: var(--brand-blue);
    }
  }

  .headerLinksFooter {
    color: white;
  }

  .menuButton {
    color: white;
  }

  .menuBurger {
    span {
      border-top: .2rem solid white;
    }
  }
}

.headerLinksFooter {
  display: none;

  @media (max-width: $md) {
    display: block;
  }

  .mainContainer {
    display: block;
    height: auto !important;
  }

  .spriteIcon {
    width: 3.6rem;
    height: 3.6rem;
  }

  .button--icon {
    margin-left: 0 !important;
  }

  .fontSMHeading {
    display: none;
  }

  .button--none {
    margin-left: 0 !important;
    padding-left: 0 !important;
  }

  .button--link {
    text-transform: none !important;
    margin-left: 0 !important;
    padding-left: 4px !important;
    padding-right: 4px !important;
  }
}

.section {
  padding-top: 4rem;
  padding-bottom: 4rem;

  &--dark {
    background-color: var(--dark-theme-bg);
    color: white;

    .ctaTitle {
      text-transform: uppercase;
      max-width: 66.666666%;
      line-height: math.div(92, 80);
      position: relative;
      top: 0.085em;

      @media (max-width: $md) {
        font-size: 4.2rem;
      }
    }
  }

  &--blue {
    background-color: var(--button-3-brand);
    color: white;
  }

  &--center {
    text-align: center;

    .mainContainer {
      justify-content: center;
      flex-direction: column;
      align-items: center;
    }
  }

  &--light {
    padding-top: 12rem;
    padding-bottom: 12rem;
  }
}

.cta {
  position: relative;
  padding-top: 7.2rem;
  padding-bottom: 7.2rem;

  // overflow: hidden;
  &Content {
    position: relative;
    z-index: 1000;
    margin-top: -0.75em;

    p {
      @extend .font1;

      +.button {
        margin-top: 2.4rem;
      }
    }
  }

  .mainContainer {
    display: flex;
    position: relative;
    z-index: 1000;
  }

  &Title {
    font-size: 6.4rem;
    font-family: var(--font-condensed-semibold);
    margin-top: -0.35em;
    line-height: math.div(94, 64);
    text-transform: uppercase;
  }
}

// CTA
.cta {
  position: relative;

  &--column {
    .mainContainer {
      flex-direction: column;
    }
  }

  &Actions {
    padding-top: 4rem;
    display: flex;
    align-items: center;
    margin-left: -1rem;
  }

  &Action {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  // Possibly can remove
  &Image {
    position: relative;
    width: 100%;

    &:before {
      width: 100%;
      content: '';
      display: block;
      padding-bottom: 40%;
    }

    img {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      object-fit: cover;
      object-position: center center;
      max-width: 100%;
      max-height: 100%;
    }
  }
}

.section.undecidedCta {
  padding-top: 0;
  padding-bottom: 0;

  .ctaText {
    max-width: 54rem;

    @media (max-width: $lg) {
      font-size: 16px;
      line-height: math.div(18, 16);
      letter-spacing: 0.02em;
    }
  }

  .ctaTitle {
    @media (max-width: $lg) {
      line-height: .8;
    }
  }
}

.undecidedCtaContainer {
  position: relative;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  padding-top: 4rem;
  padding-bottom: 4rem;

  @media (min-width: $lg) {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  &:before {
    position: absolute;
    top: 0;
    left: auto;
    bottom: 0;
    content: '';
    display: block;
    width: 100vw;
    background-color: #000000;
    right: 100%;
  }

  .mainContainer {
    width: 100%;
    display: block;
  }

  .ctaGraphic {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    max-width: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    @media (max-width: $md) {
      max-width: 40%;
    }

    img {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      height: 100%;
      width: auto;
    }

    svg {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      height: 100%;
      width: auto;
    }
  }

  .mobileText {
    @media (min-width: $lg) {
      display: none;
    }
  }

  .desktopText {
    display: none;

    @media (min-width: $lg) {
      display: block;
    }
  }

  .col6 {
    display: flex;
    height: 100%;
    align-items: center;

    @media (max-width: $md) {
      flex: 0 0 40%;
      max-width: 40%;

      &+.col6 {
        flex: 0 0 60%;
        max-width: 60%;
      }
    }
  }

  .row {
    align-items: center;
  }
}

.sectionTitle {
  @include font-heading();
}

// Our values
.clubValues {
  .carouselOuter {
    overflow: hidden;
  }

  .carouselInner {
    @media (min-width: $xl) {
      max-width: 80%;
    }
  }
}

.section.registerCta {
  padding-top: 0;

  .ctaTitle {
    padding-top: 2rem;
    margin-bottom: 2rem;
  }

  .ctaText {
    max-width: 44rem;
    margin-left: auto;
    margin-right: auto;
  }

  .contactCtaAngleBottom {
    display: none;
  }

  &+.contactCtaContainer {
    display: none;
  }
}

.mainDraws {
  .contactCtaContainer {
    display: none !important;
  }
}

.registerCtaGreyBlue {
  section.registerCta {
    .contactCtaAngleBottom {
      display: block;
    }

    &+.contactCtaContainer {
      display: block;
    }
  }
}

.section.contactCta {
  padding-top: 0;
  padding-bottom: 0;

  .ctaTitle {
    padding-top: 2.4rem;
    margin-bottom: 2rem;

    @media (max-width: $lg) {
      margin-bottom: 2.6rem;
      font-size: 3.3rem;
      line-height: math.div(50, 33);
    }
  }

  .ctaText {
    max-width: 44rem;
    margin-left: auto;
    margin-right: auto;

    @media (max-width: $lg) {
      @include font-sm();
    }
  }

  @media (max-width: $lg) {
    .button {
      width: 26rem;
    }
  }
}

.contactCtaContainer {
  position: relative;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 5rem;
}

.contactCtaAngle {
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: auto;
}

.contactCtaAngleBottom {
  width: 100%;
  height: auto;
}

.contactCtaAngleSpacer {
  width: 100%;
  height: auto;

  path {
    fill: rgba(0, 0, 0, 0);
  }
}

.registerCtaGreyBlue {
  .mainContainer {
    position: relative;
    top: 2rem;
    top: clamp(0rem, 2vw, 4rem);
  }

  .contactCtaAngle {
    path {
      fill: var(--dirty-grey);
    }
  }

  .contactCtaContainer {
    padding-bottom: 0;
  }

  .contactCtaAngleBottom {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: calc(100% + 4rem);

    path {
      fill: var(--brand-blue);
    }
  }
}

.section.hero {
  position: relative;
  background-color: var(--button-3-brand);
  color: white;
  padding-top: 0;
  padding-bottom: 0;
  overflow: hidden;
  background-image: url('../../wwwroot/resources/images/new/background/header_background.png');
  background-repeat: no-repeat;
  background-position: center center;
  padding-top: var(--header-height);

  @media (max-width: $sm-md) {
    padding-bottom: 0;
    background-image: none;
  }

  @media (max-width: $sm-md) {
    height: 100vh;
  }

  .heroImageMobile {
    @media (min-width: $md) {
      display: none;
    }
  }

  .heroImageDesktop {
    display: none;

    @media (min-width: $md) {
      display: block;
    }
  }

  .heroInner {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    
    @media (min-width: $smm) {
      background: none;
    }

    @media (max-width: $sm-md) {
      align-items: center;
      justify-content: space-between;
    }

    .mainContainer {
      max-width: var(--max-width);
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      z-index: 2;
    }

    .backgroundVideoContainer {
      position: absolute;
      inset: 0;
      z-index: 1;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: var(--max-width);
      margin-left: auto;
      margin-right: auto;
      pointer-events: none;

      @media (max-width: $smm) {
        height: calc(100% - 6rem);
        width: 80%;
      }

      @media screen and (min-width: 541px) and (max-width: 743px) {
        height: calc(100% - 6rem);
        width: 55%;
      }


      >video {
        height: auto;
        width: auto;
        transform: rotate(8deg);

        @media (max-width: $sm-md) {
          width: 100%;
          height: 100%;
        }

      }
    }

    .backgroundHeaderMobileContainer {
      display: none;

      @media (max-width: $sm-md) {
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 0;
        background: url('../../wwwroot/resources/images/new/background/header_background_mobile.png') center center/cover no-repeat;
        height: 25dvh;
      }
    }
  }

  .heroImage {
    position: relative;
    width: 100%;

    &:before {
      width: 100%;
      content: '';
      display: block;
      padding-bottom: 109.3%;

      @media (min-width: $md) {
        padding-bottom: 43.5425%;
      }
    }

    img {
      @include background-image();
    }
  }

  .heroTitleContainer {
    margin-top: 3.5rem !important;
    display: flex;
    flex-direction: column;
  }

  .heroTitle {
    font-family: var(--font-condensed-semibold);
    font-size: 4.2rem;
    text-transform: uppercase;
    max-width: 240rem;
    line-height: 70%;
    font-weight: 600;
    font-size: clamp(7.5rem, 14dvh, 16rem) !important;
    color: var(--gold-tint-10);

    @media (max-width: $sm-md) {
      font-size: 7.5rem !important;
    }

    @media (min-width: $md) {
      max-width: none;
    }

    @media screen and (min-width: 541px) and (max-width: 743px) {
      font-size: 10rem !important;
    }
  }

  .heroActions {
    display: flex;
    align-items: center;
    margin-left: -0.4rem;
    margin-right: -0.4rem;
    padding-top: 2.6rem;

    @media (min-width: $md) {
      margin-left: -1rem;
      margin-right: -1rem;
    }
  }

  .heroAction {
    padding-left: 0.4rem;
    padding-right: 0.4rem;

    @media (min-width: $md) {
      padding-left: 1rem;
      padding-right: 1rem;
    }

    .button {
      padding: 1rem 2.4rem;
      font-family: var(--font-condensed-semibold);
      text-transform: uppercase;

      @media (max-width: $smm) {
        font-size: 1.8rem;
        line-height: math.div(21, 18);
        letter-spacing: 0.015em;
        padding: 1.2rem 2rem;
        text-transform: uppercase;
        align-items: center;
      }

      &--new2 {
        padding: 0.9rem 2.4rem;
      }
    }
  }

  .heroContentCol {
    flex: 0 0 100%;
    max-width: 100%;
    margin-left: 4rem;
    margin-right: 4rem;

    @media (max-width: $sm-md) {
      margin-left: 0;
      margin-right: 0;
    }

  }

  .heroTitleOrder {
    order: 1;

    >div>.heroSliderPagination {
      display: none;
    }

    @media (max-width: $sm-md) {
      order: 2;
    }

    @media (max-width: $sm-md) {
      >div>.heroSliderPagination {
        display: flex;
      }
    }
  }

  .heroSlider {
    margin-top: 5.5rem;
    margin-bottom: 1.5rem;
    order: 1;
    z-index: 0 !important;
    width: 100%;
    max-width: unset !important;
    margin-left: unset !important;
    margin-right: unset !important;
    padding-left: unset !important;
    padding-right: unset !important;

    @media (max-width: $sm-md) {
      justify-content: flex-start;
      margin-top: 1.5rem;

      >div>.heroSliderPagination {
        display: none;
      }
    }
  }

  .swiperSlideContainer {
    max-height: 300px;
    width: 550px;
    height: 100%;
    background-color: #002480;
    border-radius: 5px;

    >img {
      border-radius: 0.6rem;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }

    @media (max-width: 774px) {
      max-height: 200px;
      width: 300px;
      background-color: #063399;
    }
  }
}

// Header
.header,
.headerClub {
  width: 100%;
  height: 6rem;
  position: relative;
  z-index: 9999;
  // background-color: white;
  top: 0;
  left: 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  transition: background-color 0.3s ease;

  &Blue {
    background-color: var(--header-bg);

    @media (max-width: $sm-md) {
      background-color: var(--brand-blue);
    }
  }

  &Black {
    background-color: #000000 !important;
  }

  .mainContainer {
    height: 6rem;
    color: var(--body-color);
    display: flex;
    align-items: center;
  }

  .button {
    display: inline-flex;
    align-items: center;
    margin-left: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
    text-transform: uppercase;
    font-weight: 600;
    font-family: var(--font-condensed-semibold);

    @media (min-width: $xl) {
      font-size: 2.8rem;
    }

    @media (min-width: $md) {
      color: var(--white);
    }

    &:first-child {
      margin-left: 1.6rem;
    }

    &:first-child {
      margin-left: 1.8rem;
    }

    @media (min-width: $smm) {
      margin-left: 3rem;

      &:first-child {
        margin-left: 1rem;
      }
    }

    svg {
      margin-bottom: -0.1rem;
    }
  }

  @media (min-width: $lg) {
    height: 10.3rem;

    .mainContainer {
      height: 10.3rem;
    }
  }
}

.logoLink {
  position: relative;
  display: block;

  @media (min-width: $lg) {
    margin-left: 4rem;
  }

  @media (max-width: $lg) {
    svg {
      height: 4.7rem;
    }
  }
}

.headerClub {
  background-color: var(--brand-blue);
  height: 6rem;

  .button {
    color: white;
  }

  .mainContainer {
    height: 6rem;
  }

  svg:not(.spriteIcon) {
    width: 54px;
    height: 57px;
  }

  &+main {
    margin-top: 6rem;
  }
}



.sectionGetInTouch {
  background-image: url('../../wwwroot/resources/images/new/background/get_in_touch_background.png');
  background-repeat: no-repeat;
  background-position: center center;
  padding-top: 8.5rem;
  padding-bottom: 8.5rem;

  @media (max-width: $sm-md) {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .ctaTitleSmall {
    @include n_heading7();
    text-transform: uppercase;
  }

  .ctaTitle {
    text-transform: uppercase;
    max-width: 66.666666%;
    @include n_heading3();
    margin-top: 1rem;
    color: var(--gold-tint-10);

    @media (max-width: $md) {
      max-width: 100%;
      font-size: 6rem;
    }
  }

  .ctaContent {
    margin-top: 1.5rem;

    >a {
      padding: 1rem 1.5rem;
      @include n_heading7();
    }
  }

  &--blue {
    background-color: #003DC7;
    color: white;
  }

  &--center {
    text-align: center;

    .mainContainer {
      justify-content: center;
      flex-direction: column;
      align-items: center;
    }
  }

  &--light {
    padding-top: 6rem;
    padding-bottom: 6rem;

    @media (min-width: $md) {
      padding-top: 8rem;
      padding-bottom: 8rem;
    }

    @media (min-width: $xl) {
      padding-top: 10rem;
      padding-bottom: 10rem;
    }

    @media (min-width: $xxl) {
      padding-top: 12rem;
      padding-bottom: 12rem;
    }
  }
}

.homeTiles {
  padding-bottom: 3rem;
  background-color: var(--brand-blue);
  position: relative;

  @media (max-width: $sm-md) {
    padding-bottom: 0rem;
  }
}

.homeTilesWrapper {
  background-image: url('../../wwwroot/resources/images/new/background/type_of_league_background.png');
  background-repeat: no-repeat;
  background-position: center center;
  padding-top: 10rem;
  display: flex;
  flex-direction: column;

  @media (max-width: $sm-md) {
    padding-top: 6rem;
  }
}

.homeTilesWrapperMobile {
  position: absolute;
  height: 100%;
  width: 100%;
  background-position: top;
  display: none;
  @media (max-width: $lg-md) {
    display: block;
  }
}

.homeTilesTitle {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &--order1 {
    order: 1;
  }

  &--order3 {
    order: 3;

    // @media (max-width: $sm-md) {
    //   order: 2;
    // }
  }

  &--title {
    margin-top: 3.5rem;
    margin-bottom: 4.5rem;
    text-transform: uppercase;
    font-weight: 600;
    color: var(--gold-tint-10);
    @include n_heading2();

    @media (max-width: $sm-md) {
      font-size: 4.8rem;
      margin-bottom: 2.5rem;
      text-align: center;
    }
  }

  &--desc {
    text-align: center;
    color: var(--gold-tint-10);
    @include n_xLargeRegular();
    width: 50%;

    @media (max-width: $sm-md) {
      width: 100%;
      margin-bottom: 2rem;
      @include n_baseRegular();
    }
  }

  &--surveyTitle {
    @include n_heading10();
    color: var(--gold-tint-10);
    text-transform: uppercase;
    margin-top: 1rem;
    text-align: center;
  }

  &--surveyDesc {
    @include n_mediumRegular();
    color: var(--gold-tint-10);
    margin-top: 1.8rem;
    text-align: center;
    width: 60%;

    @media (max-width: $sm-md) {
      width: 100%;
    }
  }

  &--surveybtn {
    margin-top: 2rem;
    margin-top: 1.8rem;
    font-size: 1.8rem;
  }

}

.homeTilesContainer {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 4rem;
  order: 2;

  @media (max-width: $sm-md) {
    order: 3;
    padding-bottom: 0;
  }

  @media (min-width: $lg) {
    padding-bottom: 3.2rem;
  }

  .homeTileContainer:nth-child(1) {
    transform: rotate(-3deg);

    @media (max-width: $lg-md) {
      transform: none;
    }

  }

  .homeTileContainer:nth-child(2) {
    margin-top: -12px;

    @media (max-width: $lg-md) {
      margin-top: 0;
      order: 3;
    }

  }

  .homeTileContainer:nth-child(3) {
    transform: rotate(3deg);

    @media (max-width: $lg-md) {
      transform: none;
    }

  }
}

.homeTileContainer {
  flex: 0 0 100%;
  max-width: 100%;
  padding-left: 2.2rem;
  padding-right: 2.2rem;
  border-radius: 1.2rem;
  position: relative;

  &Image {
    position: relative;
    aspect-ratio: 3 / 4.5;
    background-color: #002480;
    border-radius: 1.2rem;

    >img {
      border-radius: 1.2rem;
    }
  }


  @media (max-width: $lg-md) {
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 1.2rem;
  }


  @media (min-width: $lg-md) {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
    // padding-bottom: 3.2rem;
  }

  &--homeTileBodyContainer {
    position: absolute;
    width: 100%;
    right: 0;
    left: 0;
    top: 0;
    height: 100%;

    @media (max-width: $sm-md) {
      padding-left: 0;
      padding-right: 0;
    }

    // &--middle {
    //   padding-bottom: 12px;
    // }
  }

  &--homeTileBody {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 2rem;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(180deg, rgba(0, 0, 0, 0) 53.54%, rgba(0, 0, 0, 0.8) 92.37%);
    border-radius: 1.2rem;

    .button {
      margin-top: 2rem;
      padding: 1rem 1.5rem;
      @include n_heading7();
      text-transform: uppercase;
      letter-spacing: 0.03em;
    }
  }

  &--title {
    display: flex;
    flex-direction: column;
    @include n_heading4();
    color: var(--gold-tint-10);
    padding-bottom: 2.1rem;
    text-transform: uppercase;

    @media (min-width: $lg-md) and (max-width: 1024px) {
      font-size: 3.2rem;
      padding-bottom: 1rem;
    }

    @media (max-width: $sm-md) {
      @include n_heading5();
      padding-bottom: 1.8rem;
    }


  }

  &--desc {
    display: flex;
    flex-direction: column;
    @include n_baseRegular();
    color: var(--gold-tint-10);

    @media (min-width: $lg-md) and (max-width: 1024px) {
      font-size: 1.4rem;
    }


    @media (max-width: $sm-md) {
      @include n_smallRegular();
    }
  }

  // :nth-child(2) {
  //   margin-top: -16px;
  // }

}


.heroSliderPagination {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 1.1rem 5rem 1.2rem;
  @include n_heading7();
  cursor: pointer;

  @media (max-width: $sm-md) {
    justify-content: flex-start;
    margin: 1.6rem 0 5rem;
    @include n_heading9();
  }

  @media (max-width: $smm) {
    margin-top: 3.6rem;
  }
}

.marqueeContainer {
  background-color: var(--brand-blue);

  .marquee {
    position: relative;
    width: 100vw;
    max-width: 100%;
    height: 6.3rem;
    // overflow-x: hidden;
    background-color: #206fff;

    @media (max-width: $sm-md) {
      height: 4.8rem;
    }
  }

  .mareueeRed {
    background-color: var(--brand-red);
  }

  .track {
    position: absolute;
    white-space: nowrap;
    will-change: transform;
    animation: marquee 30s linear infinite;
    width: 100%;
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: center;

    >div {
      display: flex;
      align-items: center;
      transform: translateY(-2px);

      >div {
        height: 6.4rem;
        width: 6.4rem;
        margin: 0 1rem;
        display: flex;
        align-items: center;

        @media (max-width: $sm-md) {
          height: 4.2rem;
          width: 4.2rem;
        }

        >video {
          height: 6.2rem;
          width: 6.2rem;

          @media (max-width: $sm-md) {
            height: 4.2rem;
            width: 4.2rem;
          }
        }
      }

      >span {
        color: var(--white);
        font-family: var(--font-condensed-semibold);
        font-size: 7.2rem;
        display: flex;
        text-transform: uppercase;
        line-height: 70%;

        @media (max-width: $sm-md) {
          font-size: 4.4rem;
        }
      }
    }
  }

  .trackReverse {
    animation: marqueeReverse 30s linear infinite;
  }

  @keyframes marquee {
    from {
      transform: translateX(0);
    }

    to {
      transform: translateX(-50%);
    }
  }

  @keyframes marqueeReverse {
    from {
      transform: translateX(-50%);
    }

    to {
      transform: translateX(0);
    }
  }
}

.homeTile {
  &Image {
    &Desktop {
      width: 100%;
      height: 100%;
      border-radius: 2.1rem;
      position: absolute;
      inset: 0;
      object-fit: cover;
      
      @media (min-width: $lg) {
        display: block;
      }

      @media (max-width: $smm) {
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    &Mobile {
      width: 100%;
      max-width: 100%;

      @media (min-width: $lg) {
        display: none;
      }
    }
  }

  &Body {
    padding-top: 1.6rem;

    @media (min-width: $lg) {
      padding-top: 2.4rem;
    }
  }

  &Title {
    margin-bottom: 0.45em;
    color: var(--body-color);
    font-weight: 400;
    font-size: 2.8rem;
    line-height: math.div(24, 28);
    letter-spacing: 0.02em;

    @media (min-width: $md) {
      font-size: 3.6rem;
      line-height: math.div(42, 36);
      letter-spacing: 0.015em;
    }
  }
}

section.homeCta {
  padding-top: 3.2rem;
  padding-bottom: 3.2rem;

  @media (min-width: $md) {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  &--swap {
    background-color: var(--dirty-grey);

    @media (min-width: $md) {
      .col6md {
        order: 1 !important;
      }

      .col5lg {
        order: 2 !important;
      }
    }
  }

  .row {
    justify-content: space-between;
  }

  .mobileImage {
    display: block;
    margin-left: -2rem;
    margin-right: -2rem;
    width: calc(100% + 4rem);
    min-width: calc(100% + 4rem);
    margin-top: 4rem;
    margin-bottom: 3.2rem;

    @media (min-width: $md) {
      display: none;
    }
  }

  .desktopImage {
    display: none;

    @media (min-width: $md) {
      display: block;
    }
  }

  img {
    width: 100%;
    height: auto;
  }

  .col6md {
    order: 1;

    @media (min-width: $md) {
      order: 2;
    }
  }

  .col5lg {
    order: 2;

    @media (min-width: $md) {
      order: 1;
    }
  }
}

.homeCtaTitle {
  @include font-display();
  margin-bottom: 0.125em;
  margin-top: -0.325em;
}

.select {
  @include selectNew();

  @media (max-width: $md) {
    border: 1px solid #222222;
  }
}



.contactItem {
  display: flex;
  align-items: flex-start;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;

  &Icon {
    position: relative;
    bottom: -0.3rem;
    display: block;
    padding-right: 1rem;
  }

  &Text {
    @include font-sm();
    padding-top: 0.2rem;
  }
}

.testimonials {
  background-color: var(--brand-blue);
  color: white;
  position: relative;

  img {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    object-fit: contain;
    width: 100%;
    height: 100%;
    object-position: center right;

    @media (max-width: $lg) {
      display: none;
    }
  }

  &Gradients {
    position: absolute;
    height: 25.4rem;
    background: linear-gradient(0deg, rgba(17, 17, 17, 0) 0%, #111111 97.86%);
    transform: matrix(1, 0, 0, -1, 0, 0);
    z-index: 1;
    bottom: -1px;
    width: 100%;
    pointer-events: none;
  }

  &GradientsSm {
    display: none;

    @media (max-width: $sm-md) {
      display: flex;
      z-index: 0;
    }
  }

  &GradientsLg {
    display: flex;

    @media (max-width: $sm-md) {
      display: none;
    }
  }
}

.testimonialsContainer {
  position: relative;
  padding-top: 15rem;
  padding-bottom: 6rem;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: $sm-md) {
    padding-top: 1rem;
  }

  &Video {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 100%;
    width: auto;
    overflow: hidden;

    @media screen and (min-width: 330px) and (max-width: $smm) {
      height: 90%;
    }
  }

}

.blockquoteContainer {
  display: flex;
  height: 100%;

  .blockquote {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 5rem;
    margin-bottom: 9rem;
    flex-direction: column;

    @media (max-width: $sm-md) {
      justify-content: end;
      margin-bottom: 3rem;
    }

    .blockquoteText {
      @include n_heading5();
      color: var(--gold-tint-10);
      text-align: center;
      text-transform: uppercase;
      line-height: 104%;
      margin: 0 12%;

      @media (max-width: $smm) {
        margin: 2rem 1rem;
      }
    }

    .blockquoteText::before {
      content: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDkiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0OSA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTIyLjgxNTkgMTkuNzI4NVY0MEgwLjk0OTIxOVYyMC40NTI1QzAuOTQ5MjE5IDYuNjk2ODMgNy4zNDkyMiAwLjM2MTk4NiAyMi44MTU5IDBWMTAuNDk3N0MxNS44ODI2IDEwLjY3ODcgMTMuMDM4MSAxMi42Njk3IDEyLjg2MDMgMTkuNzI4NUgyMi44MTU5Wk00OC45NDkyIDE5LjcyODVWNDBIMjcuMDgyNlYyMC40NTI1QzI3LjA4MjYgNi42OTY4MyAzMy40ODI2IDAuMzYxOTg2IDQ4Ljk0OTIgMFYxMC40OTc3QzQyLjAxNTkgMTAuNjc4NyAzOS4xNzE0IDEyLjY2OTcgMzguOTkzNyAxOS43Mjg1SDQ4Ljk0OTJaIiBmaWxsPSIjRTMwMDFGIi8+Cjwvc3ZnPgo=");
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 10px;

      @media (max-width: $sm-md) {
        content: url("data:image/svg+xml;charset=UTF-8,%3csvg width='18' height='15' viewBox='0 0 18 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M8.23186 6.976V14.144H0.359863V7.232C0.359863 2.368 2.66386 0.127998 8.23186 0V3.712C5.73586 3.776 4.71186 4.48 4.64786 6.976H8.23186ZM17.6399 6.976V14.144H9.76786V7.232C9.76786 2.368 12.0719 0.127998 17.6399 0V3.712C15.1439 3.776 14.1199 4.48 14.0559 6.976H17.6399Z' fill='%23E3001F'/%3e%3c/svg%3e ");
      }
    }

    span:last-child {
      margin-top: 2rem;
      margin: 0 12%;
      text-align: center;
    }

    // .blockquoteText::after {
    //   content: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='18' height='15' viewBox='0 0 18 15' fill='none'%3e%3cpath d='M9.76786 7.31156V0.143555H17.6399V7.05556C17.6399 11.9196 15.3359 14.1596 9.76786 14.2876V10.5756C12.2639 10.5116 13.2879 9.80756 13.3519 7.31156H9.76786ZM0.359863 7.31156V0.143555H8.23186V7.05556C8.23186 11.9196 5.92786 14.1596 0.359863 14.2876V10.5756C2.85586 10.5116 3.94386 9.80756 3.94386 7.31156H0.359863Z' fill='%23E3001F'/%3e%3c/svg%3e");
    //   display: flex;
    //   align-items: center;
    //   justify-content: center;
    //   margin-top: 10px;
    // }
  }

}

.formLayout {
  padding-top: 4rem;
  padding-bottom: 4rem;

  @media (min-width: $md) {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  @media (min-width: $lg) {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  .row {
    justify-content: space-between;
  }
}

.formHero {
  background-color: var(--brand-blue);
  position: relative;
  height: 24rem;

  @media(min-width: $md) {
    height: 42.1rem;
  }

  &Content {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    max-width: var(--max-width);
    width: 100%;
    margin-top: 0;
    margin-left: 1rem;

    @media (min-width: $lg) {
      margin-top: 0;
      margin-left: 4rem;
    }

    @media (min-width: $xxl) {
      margin-left: 12rem;
      margin-top: 3rem;
    }

    &Back {
      display: flex;
      align-items: center;
      margin-bottom: 2.5rem;
      cursor: pointer;

      >svg {
        transform: rotate(87deg);
        color: var(--white)
      }

      >span {
        @include n_baseRegular();
        color: var(--white);
        margin-left: 1rem;
      }
    }

    >h1 {
      padding-left: 6px;
    }
  }

  &Image {
    // max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    img {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-size: cover;
      object-fit: cover;
      object-position: center center;
      width: 100%;
      height: 100%;
    }
  }
}

.formContactTitle {
  @include font-heading-sm();
}

.tcSection {
  padding-top: 3.2rem;
  padding-bottom: 3.2rem;
}

.formHero {
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  display: flex;
  justify-content: center;

  .fontPageTitle {
    text-align: center;
    line-height: 1.1;
  }
}

.rte {
  padding-top: 4rem;
  padding-bottom: 4rem;

  h1 {
    @include font-heading();
    text-align: center;
    line-height: 1.1;
    margin-bottom: 0.5em;

    @media (max-width: $lg) {
      font-size: 4.8rem;
    }

    span {
      white-space: nowrap;
    }
  }

  h2 {
    @include font-heading-3();
    text-align: center;
    margin-bottom: 1em;
  }

  h2+h3 {
    margin-top: -1em;
    margin-bottom: 4rem;
  }

  h3 {
    @include font-heading-sm();
    text-align: center;
  }

  a {
    text-decoration: underline;
  }

  ol {
    padding-left: 2rem;

    li {
      margin-bottom: 3.2rem;
    }

    ul:not(.styledList) {
      list-style-type: none;

      li {
        margin-top: 0.8rem;
        margin-bottom: 0.8rem;
      }
    }

    ul.styledList {
      list-style-type: disc;
      padding-left: 2rem;
      padding-top: 1rem;
      padding-bottom: 2rem;

      &:last-child() {
        padding-bottom: 0;
      }

      li {
        margin-top: 0.8rem;
        margin-bottom: 0.8rem;
      }
    }
  }

  li {
    p+p {
      margin-top: 1.6rem;
    }
  }
}

.homeChampionships {
  padding-top: 0;
  padding-bottom: 0;
  background-color: #999999;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  &--gradient {
    width: 100%;
    height: 30%;
    bottom: 0;
    max-height: 27rem;
    position: absolute;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 97.86%);
    transform: matrix(1, 0, 0, -1, 0, 0);
    z-index: 0;
    pointer-events: none;
  }

  >.mainContainer {
    max-width: unset;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    display: flex;
    padding: 0;

    @media (max-width: $lg-md) {
      flex-direction: column;
    }

    >.col6 {
      display: flex;
      justify-content: end;
      padding: 0;

      &:hover {
        background: #E3001F;
      }

      @media (max-width: $sm-md) {
        padding: 0;
      }

      @media (max-width: $lg-md) {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }

    >.col6:last-child {
      display: flex;
      justify-content: start;

      &:hover {
        background: #002480;
      }

      @media (max-width: $sm-md) {
        margin-top: 0;
      }
    }
  }

  .fontHeading {
    text-transform: uppercase;
    line-height: math.div(44, 42);
    margin-bottom: 2rem;
    text-align: left !important;

    @media (min-width: $md) {
      margin-bottom: 5rem;
      text-align: center !important;
    }
  }

  .col6 {
    @media (max-width: $md) {
      flex: 0 0 100%;
      max-width: 100%;

      &+.col6 {
        margin-top: 4rem;
      }
    }
  }
}

.contentContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  max-width: 800px;
  height: auto;

  &Image {
    position: relative;
    aspect-ratio: 3 / 3.85;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;

    >img {
      width: 100%;
      height: 100%;
      border-radius: 1.2rem;
      position: absolute;
      inset: 0;
      transition: transform 600ms cubic-bezier(0.000, 0.000, 0.580, 1.000);

      &:hover {
        transform: scale(1.1);
        transition: transform 0.35s ease;
      }
    }
  }

  @media (max-width: $lg) {
    width: 100%;
    max-width: 100%;
    align-items: center;
  }

  @media (max-width: $sm-md) {
    align-items: center;
  }

  .contentWrapper {
    // position: absolute;
    bottom: 0;
    margin-left: 1rem;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    width: 78%;
    z-index: 1;

    >svg {
      height: 8rem;
      width: 8rem;
    }

    @media (min-width: $lg) {
      margin-left: 6rem;
      margin-bottom: 12rem;
    }

    @media (min-width: $smm) and (max-width: $xl) {
      margin-bottom: 3rem;
      margin-left: 3rem;
      left: 0;
    }

    @media (max-width: $smm) {
      margin-left: 0rem;
      width: 100%;
      margin-bottom: 2rem;
      gap: 1rem;
      padding: 0 3rem;

      >svg {
        display: none;
      }
    }


  }



  p {
    @include n_baseRegular();
    flex-grow: 1;
    line-height: math.div(48, 32);
    color: var(--white);

    @media (max-width: $smm) {
      @include n_smallRegular();
    }
  }

  &Title {
    @include n_heading4();
    text-transform: uppercase;
    color: var(--white);

    @media (max-width: $smm) {
      @include n_heading5();
    }
  }

  &Heading {
    @include n_heading7();
    text-transform: uppercase;
    color: var(--white);
  }

  &Action {
    >a {
      padding: 1rem 2.5rem;
      @include n_heading7();
      text-transform: uppercase;
      letter-spacing: 0.03em;

      >svg {
        transform: rotate(270deg);
        fill: #000 !important;
      }
    }
  }

  svg {
    display: block;
    margin-right: 1rem;
  }
}

.ReturnOfAuckland {
  .heroImage {
    &:before {
      padding-bottom: 94.3% !important;

      @media (min-width: $md) {
        padding-bottom: 27.75% !important;
      }
    }
  }

  .heroInner {
    .heroImageAfter {
      height: 400px !important;
    }
  }

  .section.hero {
    padding-bottom: 0rem;
  }

  .homeCta {
    padding-top: 0rem;
    padding-bottom: 0rem;

    .mobileImage {
      margin-top: 0rem;
    }
  }

  .mainContainer {
    @media (max-width: $smm) {
      padding-right: 1.6rem;
      padding-left: 1.6rem;
    }
  }

  .teamLogo {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 4.2rem 0;

    span {
      margin: 0 1.8rem;
      font-family: var(--font-condensed-semibold);
      font-size: 2.8rem;
      line-height: 2.4rem;
      font-weight: 600;
      letter-spacing: 0.02em;
    }

    @media (max-width: $smm) {
      justify-content: flex-start;
      margin: 3rem 0;
    }
  }

  .colFull {
    @media (max-width: $smm) {
      padding-right: 0;
      padding-left: 0;
    }
  }

  .backgroundGrey {
    background-color: var(--dirty-grey);
  }

  section.homeCta {
    .col6md {
      order: 2;

      @media (min-width: $md) {
        order: 2;
      }
    }

    .col5lg {
      order: 1;

      @media (min-width: $md) {
        order: 1;
      }
    }
  }

  .bigSection {
    margin-top: 9rem;
    margin-bottom: 9rem;

    @media (max-width: $smm) {
      margin-top: 6.4rem;
      margin-bottom: 6.4rem;
    }
  }

  .mainTitle {
    font-family: var(--font-condensed-semibold);
    text-align: center;
    font-size: 6.4rem;
    line-height: 6.4rem;
    font-weight: 600;
    margin-bottom: 2rem;
    letter-spacing: 0.02em;
    text-transform: uppercase;

    @media (max-width: $smm) {
      text-align: left;
      font-size: 4.8rem;
      line-height: 4.4rem;
      margin-bottom: 1.6rem;
    }
  }

  .description {
    font-family: var(--font-regular);
    font-size: 2rem;
    line-height: 3rem;
    font-weight: 400;
    letter-spacing: 0.02em;
    text-transform: none;

    @media (max-width: $smm) {
      font-size: 1.6rem;
      line-height: 2.4rem;
    }
  }

  .headerTitle {
    font-family: var(--font-condensed-semibold);
    font-size: 4.5rem;
    line-height: 4.5rem;
    font-weight: 600;
    margin-bottom: 2.4rem;
    text-transform: uppercase;
    letter-spacing: 0.02em;

    @media (max-width: $smm) {
      font-size: 2.8rem;
      line-height: 2.4rem;
    }
  }

  .backgroundTransparent {
    background-color: transparent;
  }

  .infoItem {
    border: none;
  }

  .button {
    @media (max-width: $smm) {
      width: 100%;
    }
  }

  .infoItemIconAndText {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 1rem 0;

    svg {
      margin-right: 1rem;
    }

    @media (max-width: $smm) {
      flex-direction: row;
    }
  }

  .flexAlignCenter {
    display: flex;
    align-content: center;
    flex-wrap: wrap;

    @media (max-width: $smm) {
      flex-wrap: nowrap;
    }
  }

  .textCenter {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .description {
      text-align: center;
    }
  }

  .videoPlayer {
    .colFull {
      padding: 0;
    }
  }

  iframe {
    height: 65rem;
    width: 100%;
  }

  @media (max-width: $smm) {
    iframe {
      height: 17rem;
    }
  }

  .mt24 {
    margin-top: 2.4rem;
  }

  .mt20 {
    margin-top: 2rem;
  }

  .mt10 {
    @media (max-width: $smm) {
      margin-top: 1rem;
    }
  }

  .partnersLogo {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
    flex-wrap: wrap;

    img {
      margin-left: 1rem;
      margin-right: 1rem;
    }

    .hack {
      @media (max-width: $smm) {
        width: 100%;
      }
    }

    @media (max-width: $smm) {
      margin: 3rem 0;
    }
  }

  .paddingVertical48 {
    padding: 4.8rem 0;
  }
}

.admin-file-upload {
  margin-top: 10rem;
}

.errors-server {
  margin: 2rem 0rem;
}

.umbracoIntroduction {
  text-align: center !important;

  h2 {
    font-size: 9rem;
    font-family: var(--font-condensed-semibold);
    text-transform: uppercase;
    margin-bottom: 0.25em;
    font-weight: normal;
  }

  h3 {
    font-size: 2rem;
    line-height: 1.6;
    font-family: var(--font-regular);
    font-weight: normal;
  }
}


.premiership {
  background-color: #111111;

  &--nonContact {
    padding-bottom: 6.4rem;
  }
}

.premiershipTitle {
  width: 100%;
  display: flex;

  &--title {
    margin-top: 5rem;
    margin-bottom: 2.5rem;
    text-transform: uppercase;
    color: var(--gold-tint-10);
    @include n_heading4();

    @media (max-width: $sm-md) {
      @include n_heading5();
    }
  }
}

.premiershipTilesContainer {
  display: flex;
  flex-wrap: wrap;
  // padding-bottom: 4rem;
  gap: 3rem;

  @media (min-width: $lg) {
    // padding-bottom: 3.2rem;
  }
}

.premiershipTileContainer {
  flex: 0 0 100%;
  max-width: 100%;
  border-radius: 2.1rem;
  position: relative;
  height: auto;
  background: #000000;

  &Image {
    position: relative;
    aspect-ratio: 4 / 2.6;
    background: #002480;
    border-radius: 2.1rem;

    @media (max-width: $smm) {
      aspect-ratio: 3 / 3.8;
    }

    @media (max-width: $sm) {
      aspect-ratio: 3 / 4.7;
    }

  }

  @media (min-width: $lg) {
    flex: 0 0 calc(50% - 1.5rem);
    max-width: calc(50% - 1.5rem);
  }

  @media (max-width: $xl) {
    flex: 0 0 100%;
    max-width: 100%;
  }


  &--BodyContainer {
    position: absolute;
    width: 100%;
    right: 0;
    left: 0;
    top: 0;
    height: 100%;

    &--middle {
      padding-bottom: 12px;
    }
  }

  &--Header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    >span {
      @include n_heading5();
      letter-spacing: 0.03em;
      color: var(--gold-tint-10);

      @media (max-width: $sm-md) {
        font-size: 3.6rem;
      }
    }
    >div>img,
    >div>svg {
      height: 6rem;
      width: 6rem;

      @media (max-width: $sm-md) {
        width: 5.2rem;
        height: 5.2rem;
      }
    }
  }

  &--Body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 2rem;

    @media (max-width: $smm) {
      padding: 1.4rem;
    }

  }

  &--title {
    display: flex;
    flex-direction: column;
    @include n_heading4();
    color: var(--gold-tint-10);
    padding-bottom: 2.1rem;
    text-transform: uppercase;

    @media (max-width: $sm-md) {
      @include n_heading5();
    }
  }

  &--desc {
    display: flex;
    flex-direction: column;
    @include n_baseRegular();
    color: var(--gold-tint-10);

    @media (max-width: $sm-md) {
      @include n_smallRegular();
    }
  }

  &--Footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    >div:first-child {
      width: 75%;

      @media (max-width: $sm-md) {
        width: 100%;
      }
    }

    &--play {
      border-radius: 6.6rem;
      padding: 1.5rem 4rem;
      border: 4px solid #FFF7E5;
      color: var(--gold-tint-10);
      @include n_heading7();
      // margin-left: 10rem;
      text-transform: uppercase;
      cursor: pointer;

      @media (max-width: $sm-md) {
        padding: 1rem 2.4rem;
        @include n_heading8();
        border-width: 0.3rem;
      }
    }

    @media (max-width: $sm-md) {
      flex-direction: column;
      align-items: flex-start;

      &--play {
        margin-left: 0;
      }
    }
  }

  &Gradient {
    position: absolute;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(180deg, rgba(0, 0, 0, 0) 53.54%, rgba(0, 0, 0, 0.8) 92.37%);
    height: 100%;
    width: 100%;
    bottom: 0;
    border-radius: 1.9rem;
  }

}


.infoItems {
  display: flex;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  flex-wrap: wrap;
}

.infoItem {
  display: flex;
  align-items: center;
  padding-top: .4rem;
  padding-right: 1.5rem;
  padding-bottom: .4rem;
  white-space: nowrap;

  &:last-child {
    margin-right: 0;
    border: 0;
  }

  &Icon {
    display: flex;
    padding-right: 0.8rem;
    color: var(--gold-tint-10);
    height: 2.4rem;
  }

  &Text {
    @include font-sm();
    line-height: 1;
  }
}

.infoItemText {
  @include n_heading7();
  text-transform: uppercase;
  color: var(--gold-tint-10);

  @media (max-width: $sm-md) {
    @include n_heading9();
  }
}

.testimonialsCarousel {
  &--navigation {
    display: flex;
    justify-content: center;
    gap: 0 4rem;
    padding-top: 0;

    @media (max-width: $md) {
      padding-top: 4rem;
    }
  }
}

.whatToPlay {
  background-image: url('../../wwwroot/resources/images/new/background/joinTheGame.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  background-color: #560a10;

  &--container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &--video {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;

    >video {
      width: 100%;
      height: 100%;
      max-width: 80rem;
    }
  }

  &--wrapper {
    padding-top: 24rem;
    padding-bottom: 24rem;
    z-index: 1;

    @media (max-width: $sm-md) {
      padding-top: 11rem;
      padding-bottom: 7rem;
    }

    &--title {
      @include n_heading3();
      color: var(--gold-tint-10);
      text-align: center;
      text-transform: uppercase;
      padding: 0 1.6rem;
    }

    &--desc {
      @include n_baseRegular();
      color: var(--gold-tint-10);
      text-align: center;
      margin: 2.4rem;
    }

    &--button {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      gap: 1.2rem;

      >.button--new1 {
        font-family: var(--font-condensed-semibold);
        text-transform: uppercase;
      }

      >.button--new2 {
        font-family: var(--font-condensed-semibold);
        text-transform: uppercase;
        padding: 0.85rem 1.4rem 0.85rem 1.4rem;
      }

      >a>svg {
        transform: rotate(270deg)
      }
    }

  }
}

.footerContainer {
  background-color: #1A1524;
  background-image: url('../../wwwroot/resources/images/new/background/footer_background.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  &Title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 8rem;
    padding-bottom: 10rem;

    @media (max-width: $smm) {
      padding-bottom: 15rem;
    }

    >span {
      font-family: var(--font-condensed-semibold);
      font-size: 27rem;
      color: #154BF6 !important;
      text-transform: uppercase;
      line-height: 70%;

      @media (max-width: $xl) {
        font-size: 23rem;
      }

      @media (max-width: $lg-md) {
        font-size: 18rem
      }

      @media screen and (min-width: 541px) and (max-width: 855px) {
        font-size: 13rem;
      }

      @media (max-width: $smm) {
        font-size: 9rem;
      }

      @media (max-width: $sm) {
        font-size: 8rem;
      }
    }

  }
}

.newsBannerHome {

  background-color: #111111;
  padding-bottom: 6.6rem;

  @media (max-width: $sm-md) {
    padding-bottom: 0rem;
  }

  &Header {
    background-image: url('../../wwwroot/resources/images/new/background/home_news_background.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding-top: 2.5rem;
    padding-bottom: 4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.6rem;

    >h2 {
      @include n_heading3();
      color: var(--gold-tint-10);
      text-transform: uppercase;
      text-align: center;
    }

    &SubTitle {
      @include n_baseRegular();
      color: var(--gold-tint-10);
    }
  }

  &Title {
    @include n_heading4();
    color: var(--gold-tint-10);
    text-transform: uppercase;
  }

}

.imageSlider {
  overflow: hidden;
  padding: 30px 0 0 0;
  white-space: nowrap;
  position: relative;
  display: flex;
  align-items: center;

  .imagesSlide {
    display: flex;
    animation: 120s slide infinite linear;

    .slide {
      display: inline-block;
      padding: 0 5px;

      h2 {
        font-size: 16px;
        text-align: left;
        font-family: 'Arial';
        font-style: normal;
        margin: 5px 0;
      }
    }

    img {
      max-width: fit-content;
      max-height: 300px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-grow: 1;
      height: 100%;
      object-fit: cover;

      @media (max-width: 540px) {
        max-width: 380px;
        max-height: 240px;
      }
    }
  }
}

@keyframes slide {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-100%);
  }
}