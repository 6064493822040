@use "sass:math";
@import 'Variables';
@import 'Mixins';


.registerMainContainer {
    max-width: 864px !important;
}

.registerHeaderTitle {
    font-family: var(--font-regular);
    font-size: 4.5rem;
    line-height: 4.5rem;
    letter-spacing: 0.02em;
    margin-bottom: 3.9rem;

    @media (max-width: $smm) {
        font-size: 2.6rem;
        line-height: 2.4rem;
        margin-bottom: 1.5rem;
    }
}

.removeGutters {
    padding-left: 0;
    padding-right: 0;
}

.questionContainer {
    margin-top: 10rem;

    @media (max-width: $smm) {
        margin-top: 4.2rem;
    }
}

.cardSelectorContainer {
    display: flex;
    font-family: var(--font-regular);
    font-weight: 400;
    font-size: 1.6rem;
    letter-spacing: 0.015em;
    cursor: pointer;
    margin: 0 -3.9rem;
    justify-content: space-between;
    width: auto;

    @media (max-width: $smm) {
        margin: 0 -0.75rem;
    }

    >.cardSelector {
        display: flex;
        flex-direction: column;
        margin-right: 3.9rem;
        margin-left: 3.9rem;
        width: 100%;

        @media (max-width: $smm) {
            margin-right: 0.75rem;
            margin-left: 0.75rem;
        }

        >.test::before {
            content: '';
            aspect-ratio: 1;
            display: flex;
        }

        >.test {
            position: relative;

            >.cardSelectorImage {
                border: 1px solid #B8B8B8;
                border-radius: 0.2rem;
                text-align: center;
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                display: flex;
                align-items: center;
                justify-content: center;

                >svg {
                    --icon-color: #999;
                    height: auto;
                    width: 29%;
                }

                &.active {
                    border: 0.5rem solid #2D5BD2;
                    box-shadow: 0px 1.26px 3.37px 0px rgba(12, 44, 212, 0.32);

                    @media (max-width: $smm) {
                        border-width: 0.2rem;
                    }

                    .spriteIcon {
                        fill: #2D5BD2;
                    }
                }
            }
        }

        >.cardSelectorText {
            font-size: 2.2rem;
            margin-top: 1.5rem;
            color: #999999;

            @media (max-width: $smm) {
                font-size: 1.4rem;
            }

            &.active {
                color: #000000;
            }
        }

    }
}

.formContainer {
    .registerHeaderTitle {
        margin-bottom: 1.5rem;
    }
    @media (max-width: $smm) {
        .basicInfo,
        .contactInfo {
            width: 100%;
        }
    }

    .group {
        width: 48%;
        margin-bottom: 1.5rem;

        @media (max-width: $smm) {
            width: 100%;
        }

        .input {
            @include input();
        }

        .label {
            font-family: var(--font-regular);
            font-weight: 400;
            font-size: 2rem;
            letter-spacing: 0.015em;
            color: #000000;
            margin-bottom: 1.5rem;
            display: block;

            @media (max-width: $smm) {
                font-size: 1.6rem;
            }
        }

        .labelDesc {
            font-size: 1.4rem;
            color: #999999;
            margin-bottom: 1.5rem;
            display: block;
            margin-top: -10px;
        }

        .counts {
            font-size: 1.4rem;
            color: #999999;
            display: block;
            margin-top: 1.5rem;
            text-align: end;
        }

        .mobileUpload {
            display: none;

            @media (max-width: $smm) {
                display: block;
            }
        }

        .webUpload {
            display: block;

            @media (max-width: $smm) {
                display: none;
            }
        }
    }

    .addressContainer {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 0 3.2rem;
    }

    .w100 {
        width: 100%;
    }
}

.datePicker {
    min-height: 4.1rem;
}

.labelDescpc {
    font-size: 1.4rem;
    color: #999999;
    margin-top: 1.5rem;
    display: block;
}

.chipContainer {
    display: flex;
    align-items: center;
    font-family: var(--font-regular);
    font-weight: 400;
    font-size: 1.6rem;
    letter-spacing: 0.015em;
    flex-wrap: wrap;
    cursor: pointer;
    gap: 13px;

    >.chipText {
        flex: 0 0 calc(33.33% - 1.3rem);
        border: 1px solid #B8B8B8;
        border-radius: 0.2rem;
        padding: 1rem;
        text-align: center;
        color: #999999;
        font-weight: 400;
        font-size: 2rem;
        font-family: var(--font-regular);

        &.active {
            font-family: var(--font-medium);
            color: #2D5BD2;
            border: 0.5rem solid #2D5BD2;
            box-shadow: 0px 1.26px 3.37px 0px rgba(12, 44, 212, 0.32);
            font-weight: 600;

            @media (max-width: $smm) {
                border-width: 0.2rem;
            }
        }

        @media (max-width: $smm) {
            flex: 0 0 100%;
        }


    }
}

.imagePreview {
    height: 14.3rem;
    width: 14.3rem;
    position: relative;

    &.headShot {
        height: 11.4rem;
        width: 14.3rem;
        object-fit: cover;
    }

    &.pdf {
        height: 7.6rem;
        width: 5.7rem;
    }

    .close {
        position: absolute;
        top: -9px;
        right: -13px;
        cursor: pointer;
        color: white;
        /* font-size: 2.2rem; */
        background-color: #999999;
        /* padding: 1rem; */
        border-radius: 100%;
        height: 24px;
        width: 24px;
        display: flex;
        align-items: center;
        justify-content: center;

        >span {
            padding-bottom: 4px;
            padding-left: 1px;
        }
    }

    >img,
    svg {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }

    @media (max-width: $smm) {
        height: 15rem;
        width: 15rem;
    }

}

.uploadContainer {
    border: 1px solid #002480;
    position: relative;
    width: 50%;
    border-radius: 3px;
    box-shadow: 0px 1.26px 3.37px 0px rgba(12, 44, 212, 0.32);

    @media (max-width: $smm) {
        width: 100%;
    }

    >.filelabel {
        justify-content: center;
        display: flex;
        margin: 1.4rem 0;
        text-align: center;
        color: #002480;
        font-weight: 700;

        >span {
            margin-left: 1rem;
        }
    }

    &.errorStateUpload {
        box-shadow: 0px 1.26px 3.37px 0px rgba(227, 0, 31, 0.47) !important;
        border: 1px solid #E3001F !important;
    }
}

.dragDrop {
    max-height: 258px;
    padding: 9.7rem 0;
    border: 3px dashed #B8B8B8;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 10px auto;

    .dragArea {
        display: flex;
        flex-direction: row;
        justify-content: center;

        &--icon {
            svg {
                height: 6.3rem;
                width: 6.3rem;
            }

            margin-right: 4.5rem;
        }

        &--textContainer {
            display: flex;
            flex-direction: column;
        }

        &--header {
            font-family: var(--font-semibold);
            font-size: 2.2rem;
            color: #000000;

            >span {
                color: #2D5BD2;
                text-decoration: underline;
            }
        }

        &--support {
            font-family: var(--font-regular);
            font-size: 2rem;
            color: gray;
            margin: 10px 0 15px 0;
        }

        &--button {
            font-size: 20px;
            font-weight: 500;
            color: #1683ff;
            cursor: pointer;
        }

        &--active {
            border: 2px solid #1683ff;
        }

    }


    &.errorStateUpload {
        box-shadow: 0px 1.26px 3.37px 0px rgba(227, 0, 31, 0.47) !important;
        border: 3px dashed #E3001F !important;
    }
}

.dividerLine {
    margin: 4rem 0;
    border: 1px solid var(--dirt-grey);
}

.buttonContainer {
    display: flex;
    flex-direction: column;
    width: auto;
    max-width: 51rem;
    padding: 1.4rem 0;

    >button {
        max-width: 31.9rem;
        @media (max-width: $smm) {
            max-width: 100%;
        }
    }

    @media (max-width: $smm) {
        width: 100%;
        max-width: 100%;
    }

    .tAndc {
        font-family: var(--font-regular);
        font-size: 1.6rem;
        color: #999999;
        margin-top: 2.3rem;

        >a {
            text-decoration: underline;
            cursor: pointer;
        }
    }

}

.ab_accordion {
    margin-top: 2rem;

    .ab_accordion_content {
        display: none;
    }

    .ab_accordion_header {
        font-family: var(--font-regular);
        font-size: 2.2rem;
        font-weight: bold;
        cursor: pointer;
        background-color: #ffffff;
        padding: 18px;
        margin-top: 15px;
        border: 1px solid #B8B8B8;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media (max-width: $smm) {
            font-size: 1.6rem;
        }
    }

    .ab_accordion_header.active {
        background-color:
            #9379f9;
        color:
            #fff;
    }

    .ab_accordion_content {
        padding: 12px;
        animation: fade-in-up 0.3s ease-in-out;
        border-left: 1px solid #ededed;
        border-right: 1px solid #ededed;
        border-bottom: 1px solid #ededed;
    }

    @keyframes fade-in-up {
        0% {
            opacity: 0;
            transform: translateY(20px);
        }

        100% {
            opacity: 1;
            transform: translateY(0);
        }
    }
}

.clubOption {
    display: flex;
    align-items: center;

    >img {
        height: 4rem;
        width: 4rem;
        margin-right: 1rem;

        @media (max-width: $smm) {
            height: 3rem;
            width: 3rem;
        }
    }

    >span {
        font-family: var(--font-regular) !important;
        font-size: 2rem;
        color: #000000;

        @media (max-width: $smm) {
            font-size: 1.6rem;
        }
    }
}

.errorMessage {
    font-family: var(--font-regular);
    font-size: 2rem;
    color: #E3001F;
    margin-top: 1.2rem;
    display: flex;

    @media (max-width: $smm) {
        font-size: 1.6rem;
    }

    &.medicalConditions {
        margin-top: -1.8rem;
    }
}

.hasError {
    border: 1px solid #E3001F !important;
}

.hasOptionError {

    >div {
        border: 1px solid #E3001F !important;
        box-shadow: none;
    }

    input {
        outline: none;
    }
}

// Modal
.modaloverlay {
    background: rgba(0, 0, 0, 0.8);
    bottom: 0;
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: fixed;
    right: 0;
    top: 0;
    -webkit-transition: opacity 400ms ease-in;
    -moz-transition: opacity 400ms ease-in;
    transition: opacity 400ms ease-in;
    z-index: -1;
    display: none;

    &:target {
        display: block;
        opacity: 1;
        pointer-events: auto;
        z-index: 99999;
    }

    .modal {
        border-radius: 1rem;
        background-color: white;
        position: relative;
        -webkit-overflow-scrolling: touch;
        width: 55%;
        padding: 0;
        float: left;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        @media (max-width: $smm) {
            width: 85%;
            padding: 2rem 0;
        }


        @media screen and (max-width: 1400px) {
            width: 85%;
        }


        >iframe,
        >div {
            border: none;
            width: 100%;
        }

        .modalContainer {
            padding: 5.5rem 6.3rem;
            overflow: auto;
            max-height: calc(100vh - 10%);
            overflow: auto;

            @media (max-width: $smm) {
                padding: 0rem 2rem 2rem;
            }

            >span {
                font-family: var(--font-medium);
                font-size: 2.6rem;
                line-height: 2.6rem;
                letter-spacing: 0.02em;
                margin-bottom: 2.5rem;
                display: flex;
            }

            >p {
                font-family: var(--font-regular);
                font-size: 1.6rem;
                line-height: 2.6rem;
                letter-spacing: 0.02em;
                margin-bottom: 1.5rem;

                @media (max-width: $smm) {
                    font-size: 1.4rem;
                }
            }

            .footer {
                text-align: center;

                >button {
                    color: #002480;
                    background: white;
                    border-color: #002480;
                    max-width: 319px;
                    width: 35%;

                    @media (max-width: $sm) {
                        width: 100%;
                    }
                }

                >button:hover {
                    box-shadow: 0px 1.26px 3.37px 0px rgba(12, 44, 212, 0.32);
                }
            }
        }
    }

    .close {
        cursor: pointer;
        color: black;
        font-size: 2.5rem;
        padding: 2.4rem;
        position: absolute;
        right: 0;
        text-align: center;
        text-decoration: none;
        top: 0;
        z-index: 1;

        @media (max-width: $smm) {
            padding: 0rem 1rem;
            top: -0.5rem;
            right: -0.3rem;
        }
    }
}

.spriteIcon {
    fill: #999;
}

.link {
    font-weight: bold;
    color: #002480;
}

.formHeader {
    margin-bottom: 3rem;
}

.thankYouContainer {
    max-width: 864px !important;
    padding: 2rem 2rem;

    @media (max-width: $smm) {
        padding: 4rem 2rem;
    }

    .thankYouText {
        font-family: var(--font-regular);
        font-size: 3.2rem;
        letter-spacing: 0.02em;
        margin-bottom: 3.9rem;

        @media (max-width: $smm) {
            font-size: 1.6rem;
            line-height: 2.4rem;
            margin-bottom: 1.5rem;
        }
    }
}