@import 'Variables';
// @import 'Animations';
@import 'Mixins';
@import 'Type';
@import 'Buttons';
@import 'Cards';
@import 'Layout';


.select {
  @include selectNew();
}

.mainDraws {
  background-color: var(--button-3-brand);
}

.pageTop {
  padding-top: 2rem;
  @media (min-width: $lg) {
    padding-top: 5rem;
  }
}

.pageTopActions {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;

  >a {
    color: #fff;
  }

  >button>a {
    color: #fff;
  }
}

.headerDrawsFilter {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 3rem;

  .fontPageTitle {
    line-height: 1;
  }

  .drawsFilter {
    padding-top: .6rem;
    display: flex;
    margin-left: -.8rem;
    margin-right: -.8rem;

    @media (max-width: 720px) {
      flex-direction: column;
      padding-left: 0;
      flex: 0 0 100%;
      max-width: 100%;
      width: 100%;
      .filterItem {
        width: 100%;
      }
    }
  }
  @media (max-width: 768px) {
    .filterItem {
      padding-left: .8rem;
      padding-right: .8rem;
    }
  }

  .clubImageContainer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  .clubImage {
    width: 18rem;
    display: none;
  
    @media (min-width: 720px) and (max-width: $lg) {
      display: flex;
    }
  }
}

.fixturesContainer {
  max-width: var(--max-width);
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.fixtures {
  border-radius: 1rem;
  background-color: var(--body-color);
  padding-left: 2rem;
  padding-right: 2rem;
  text-align: center;
  padding-bottom: 4rem;
  @media (min-width: $xl) {
    padding-bottom: 6rem;
  }
}

.fixturesLoader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40rem;
}

.fixturesDates {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  cursor: grab;
  width: 100%;
  max-width: 69.2rem;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  @media (min-width: $lg) {
    max-width: 91.6rem;
  }
  @media (min-width: $xl) {
    max-width: 114rem;
  }
  &Container {
    display: flex;
    align-items: center;
  }
}

.fixturesNext,
.fixturesPrev {
  border-radius: 50%;
  flex-shrink: 0;
}
.fixturesPrev {
  svg {
    transform: rotate(90deg);
  }
}
.fixturesNext {
  svg {
    transform: rotate(-90deg);
  }
}

.fixturesDateOption {
  background-color: rgba(0, 0, 0, 0);
  flex: 4.8rem;
  max-width: 4.8rem;
  border-radius: .6rem;
  border: 0;
  color: white;
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-size: 1.4rem;
  font-family: var(--font-medium);
  flex-shrink: 0;
  cursor: pointer;
  &:hover {
    background-color: black;
  }
  &.fixturesDateOptionActive {
    background-color: var(--action-blue);
    pointer-events: none;
  }
  @media (min-width: $lg) {
    flex: 5.6rem;
    max-width: 5.6rem;
    margin-left: .4rem;
    margin-right: .4rem;
    flex-shrink: 0;
  }
  @media (min-width: $xl) {
    flex: 5.6rem;
    max-width: 5.6rem;
    margin-left: 1.2rem;
    margin-right: 1.2rem;
    font-size: 2rem;
    flex-shrink: 0;
  }
}

.fixturesDate {
  color: var(--action-blue-text);
  font-family: var(--font-medium);
  padding-top: 2rem;
  padding-bottom: .6rem;
  @media (min-width: $md) {
    padding-top: 4.4rem;
    padding-bottom: 2rem;
  }
}

.fixturesTitle {
  border-radius: .5rem;
  color: white;
  text-align: center;
  font-size: 2.4rem;
  border-radius: 1rem 1rem 0rem 0rem;
  padding-top: .9rem;
  padding-bottom: 1.1rem;
  font-size: 2.2rem;
}

.fixture {
  border: 1px solid #D7D7D7;
  padding: 1.6rem;
  background-color: white;
  display: flex;
  flex-wrap: wrap;
  max-width: 1310px;
  margin-left: auto;
  margin-right: auto;
  @media (min-width: $lg) {
    padding-left: 2.4rem;
    padding-right: 2.4rem;
  }
  &:first-child {
    border-top: 0;
  }
  &Teams {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-left: -1rem;
    margin-right: -1rem;
    @media (min-width: $lg) {
      flex: 0 0 45%;
      max-width: 45%;
    }
  }
  &Team {
    flex: 0 0 calc(50% - 32px);
    max-width: calc(50% - 32px);
    align-self: center;
    display: flex;
    align-items: center;
    padding-left: 1rem;
    padding-right: 1rem;
    @media (min-width: $lg) {
      flex: 0 0 calc(50% - 24px);
      max-width: calc(50% - 24px);
    }
    @media (min-width: $md) {
      justify-content: center;
    }
    + .fBodyStrong {
      margin-left: 1rem;
      margin-right: 1rem;
    }
  }
  &TeamLogo {
    width: 4.2rem;
    height: 4.2rem;
    flex-shrink: 0;
    @media (min-width: $xl) {
      width: 6.8rem;
      height: 6.8rem;
    }
  }
  &TeamTitle {
    padding-left: .8rem;
    padding-right: .8rem;
    @media (min-width: $md) {
      padding-left: 1.6rem;
      padding-right: 1.6rem;
    }
  }
  &Info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 0 0 100%;
    max-width: 100%;
    
    @media (max-width: $lg) {
      padding-top: 1rem;
      display: none;
      flex-wrap: wrap;
      justify-content: space-evenly;
    }
    @media (min-width: $lg) {
      flex: 0 0 55%;
      max-width: 55%;
    }
    @media (max-width: 680px) {
      justify-content: center;
    }
  }
  .infoDetails {
    flex: 0 0 24rem;
    max-width: 24rem;
    @media (max-width: $lg) {
      padding-top: 1rem;
      flex: 0 0 206px;
      max-width: 206px;
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }
  .infoLink {
    @media (max-width: $lg) {
      flex: 0 0 206px;
      max-width: 206px;
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }
  &Date,
  &Loaction {
    display: block;
    text-align: center;
  }
  &.fixtureIsOpen {
    .fixtureInfo {
      display: flex;
    }
    .fixtureToggle {
      transform: rotate(-180deg);
    }
  }
}

.fixtureToggle {
  background: var(--light-grey);
  border-radius: 50%;
  display: flex;
  width: 2.3rem;
  height: 2.3rem;
  color: white;
  padding: 0;
  justify-content: center;
  align-items: center;
  border: 0;
  margin-left: 1rem;
  margin-right: 1rem;
  cursor: pointer;

  @media (min-width: $lg) {
    display: none;
  }
  span {
    display: block;
    width: 2.3rem;
    height: 2.3rem;
  }
}

.infoDetails {
}
.infoLink {
  color:var(--button-3-brand)
}
.infoMapCTA {
  @media (max-width: 680px) {
    flex: 0 0 100%;
    max-width: 100%;
    padding-top: 1.4rem;
    .button {
      width: 100%;
    }
  }
  @media (min-width: $lg) {
    flex: 0 0 auto;
    max-width: auto;
  }
  .mobileText {
    @media (min-width: $lg) {
      display: none;
    }
  }
  .desktopText {
    display: none;
    @media (min-width: $lg) {
      display: block;
    }
  }
}

.fixtureGame {

}

.fixtureTeam {

}

.fBody {
  font-size: 1.4rem;
  font-family: var(--font-regular);
  &Strong {
    font-size: 1.4rem;
    font-family: var(--font-semibold);
  }
}

.light {
  font-family: var(--light-grey);
}

.fixturesMessage {
  background-color: var(--dirty-grey);
  max-width: 76.2rem;
  margin-top: 2rem;
  margin-left: auto;
  margin-right: auto;
  border-radius: 2.6rem;
  padding: 6rem 3rem;
  &Icon {
    margin-bottom: 2rem;
  }
  &Title {
    font-size: 2.8rem;
    padding-bottom: 1.2rem;
  }
  p {
    max-width: 52rem;
    margin-left: auto;
    margin-right: auto;
    font-size: 1.6rem;
    @media (min-width: $md) {
      font-size: 2.2rem;
      line-height: 2.6rem;
    }
  }
}

// 
.filter {
  &Item {
    width: 100%;
    padding: 0.4rem 0;
    max-width: 35rem;
    @media (min-width: $md) {
      padding: 0.4rem 0.8rem;
    }
    .select {
      width: 100%;
      padding: 0.5rem 0.2rem;
    }
    @media (max-width: $md) {
      max-width: 100%;
    }
  }
}

// Contact CTA v2
.ctaV2 {
  background-color: var(--brand-blue);
  margin-top: -10rem;
  padding-top: 14rem;
  padding-bottom: 6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: white;
  @media (max-width: 640px) {
    background-color: #00195a;
    margin-top: -2rem;
    padding-top: .8rem;
    padding-bottom: 4rem;
  }
  &Title {
    text-transform: uppercase;
    font-family: var(--font-condensed-semibold);
    font-size: 4.8rem;
    padding-bottom: 1.6rem;
  }
  &Logo {
    @media (min-width: 640px) {
      display: none;
    }
    img {
      width: 100%;
      max-width: 414px;
    }
  }
  p {
    margin-bottom: 2.2rem;
  }
  &Content {
    @media (max-width: 640px) {
      display: none;
    }
  }
  &Footer {
    @media (max-width: 640px) {
      padding-top: 2rem;
    }
  }
}

.fontRegular {
  font-family: var(--font-regular) !important;
}

.cursorPointer {
  cursor: pointer;
}

.formHero {
  background-color: var(--brand-blue);
  position: relative;
  height: 24rem;

  @media (max-width: $sm-md) {
    height: 34rem;
  }

  @media(min-width: $md) {
    height: 42.1rem;
  }

  &Content {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    flex-direction: column;
    height: 100%;
    max-width: var(--max-width);
    width: 100%;
    margin-top: 0;
    margin-left: 1rem;

    @media (min-width: $lg) {
      margin-top: 0;
      padding-left: 4rem;
    }

    @media (min-width: $xxl) {
      padding-left: 6rem;
      margin-top: 3rem;
    }

    &Back {
      display: flex;
      align-items: center;
      margin-bottom: 2.5rem;
      cursor: pointer;
      
      >svg {
        transform: rotate(87deg);
        color: var(--white)
      }

      >span {
        @include n_baseRegular();
        color: var(--white);
        margin-left: 1rem;
      }
    }

    >h1 {
      padding-left: 6px;
    }
  }

  &Image {
    margin-left: auto;
    margin-right: auto;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    img {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-size: cover;
      object-fit: cover;
      object-position: center center;
      width: 100%;
      height: 100%;
    }
  }
}

.formHero {
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  display: flex;
  justify-content: center;

  .fontPageTitle {
    text-align: left;
    line-height: 1.1;
  }
}