@import './mixins.scss';

.chipButton {
  display: flex;
  align-items: center;

  >div {
    border: 2px solid var(--gold-tint-10);
    border-radius: 1.6rem;
    padding: 0.5rem 0.7rem;
    @include n_heading7();
    color: var(--gold-tint-10);
    text-transform: uppercase;

    @media (max-width: $sm-md) {
      @include n_heading9();

    }
  }

  >span {
    @include n_heading7();
    color: var(--gold-tint-10);
    text-transform: uppercase;
    margin-left: 0.4rem;
  }
}

.chipTags {
  display: flex;
  align-items: center;
  margin-right: 0.5rem;

  >div {
    border: 2px solid white;
    border-radius: 1.6rem;
    padding: 0.5rem 0.7rem;
    @include n_heading7();
    color: white;
    text-transform: uppercase;
    display: flex;
    align-items: center;

    @media (max-width: $sm-md) {
      @include n_heading9();
    }

    >span {
      font-size: 1.4rem;
      margin-right: 0.2rem;
    }

    >svg {
      height: 1.4rem;
      width: 1.4rem;
      margin-right: 0.2rem;
    }
  }

  >span {
    @include n_heading7();
    color: white;
    text-transform: uppercase;
    margin-left: 0.4rem;
  }

  &Close {
    margin-left: 1rem;
    cursor: pointer;

    >svg {
      height: 1.6rem;
      width: 1.6rem;
    }
  }
}

.isContrast {
  >div {
    border-color: black;
    color: black;
  }

  >span {
    color: black;
  }
}

.white {
  >div {
    border-color: white;
    color: white;
  }

  >span {
    color: white;
  }
}