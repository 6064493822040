@use "sass:math";

.fontSMHeading {
  font-family: var(--font-semibold);
  font-size: 1.6rem;
  line-height: math.div(19, 16);
  text-transform: uppercase;
}

.font1 {
  font-size: 2.4rem;
  line-height: math.div(36, 24);
  font-family: var(--font-regular);
  font-weight: 400;
  margin-bottom: 0.5em;
}

.font2 {
  font-family: var(--font-regular-italic);
}

.font3 {
  font-family: var(--font-medium);
}

.font4 {
  font-family: var(--font-semibold);
  font-size: 2.4rem;
}

.font5 {
  font-family: var(--font-condensed-semibold);
  font-weight: 600;
  font-size: 10.2rem;
  letter-spacing: 0.015em;
}

.font6 {
  font-family: var(--font-condensed-medium);
  font-weight: 600;
  font-size: 6.4rem;
  line-height: math.div(94, 64);
  text-transform: uppercase;
}

.font-sm {
  @include font-sm();
}

.fontCopy {
  font-size: 2rem;
  line-height: math.div(32, 20);
  font-family: var(--font-regular);

  &+.fontCopy {
    margin-top: 2.4rem;
  }

  a {
    color: var(--brand-blue);
    text-decoration: underline;

    &:hover {
      color: var(--sky-blue);
    }
  }
}

.fontHeading {
  font-family: var(--font-condensed-semibold);
  font-size: 4.2rem;
  line-height: math.div(44, 42);
  margin-bottom: 0.8rem;

  @media (min-width: $md) {
    font-size: 5.6rem;
  }

  @media (min-width: $xl) {
    font-size: 7rem;
  }

  .oneColumn & {
    text-transform: uppercase;
    margin-bottom: 0.25em;

    @media (min-width: $lg) {
      text-align: center;
      font-size: 9rem;
    }
  }
}

.fontHeading3 {
  @include font-heading-3();
}

.fontPageTitle {
  @include font-page-title();
}

.fontDisplay {
  @include font-display();
}

// Icons
.spriteIcon {
  height: 2.4rem;
  width: 2.4rem;
  display: inline-block;
  fill: currentColor;
}
