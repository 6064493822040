@import 'Variables';
@import 'Mixins';

.form {
    padding-bottom: 2rem;
}

.input {
    @include input();
}

.select {
    @include select();
}

.label {
    @include label();
}

.textarea {
    @include textarea();
}

.radioGroup {
    display: inline-flex;
    align-items: center;
    background: var(--dirty-grey);
    border: 1px solid var(--dirt-grey);
    border-radius: 4px;
    padding: 0.2rem 0;
    &Button {
        position: relative;
        margin-left: 0.2rem;
        margin-right: 0.2rem;
        transition: all .35s ease;
        &:hover {
            background-color: white;
        }
        input {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            appearance: none;
            cursor: pointer;
            &:checked ~ {
                span {
                    background: #FFFFFF;
                    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
                }
            }
        }
        span {
            display: inline-flex;
            border-radius: var(--border-radius);
            padding: 1.2rem 2.4rem;
        }
    }
}

.group {
    margin-bottom: 2.4rem;
}

.divider {
    padding-top: 3.2rem;
    border-top: 1px solid var(--dirt-grey);
}

.sectionTitle {
    @include font-heading();
}

.formComplete {
    margin-top: -2rem;
    @media (min-width: $md) {
        margin-top: -4rem;
    }
    @media (min-width: $lg) {
        margin-top: -6.4rem;
    }
}

.formMessage {
    padding-top: 3rem;
    padding-bottom: 10rem;  
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    p {
        max-width: 640px;
        margin-left: auto;
        margin-right: auto;
    }
    &Title {
        color: black;
        font-family: var(--font-condensed-semibold);
        font-size: 7rem;
        margin-bottom: 1rem;
        @media (max-width: $lg) {
            font-size: 4.2rem;
        }
    }
    &Icon {
        padding-bottom: 4rem;
    }
}