@use "sass:math";

@import 'Mixins';
@import 'Type';
@import 'Layout';

// Footer
.footer {
  position: relative;
  width: 100%;
  padding-top: 0rem;
  padding-bottom: 3.2rem;
  color: var(--footer-color);
  background-color: var(--footer-bg-news);

  * {
    color: inherit !important;
    position: relative;
  }

  @media (min-width: $lg) {
    // padding-top: 3.6rem;
    padding-bottom: 4.4rem;
  }

  &Header {
    background-color: rgba(0, 0, 0, 0);
    color: inherit !important;
    padding-top: 3rem;
  }
}

.footerVideo {
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: end;

  >video {
    margin-bottom: -60px;

    @media screen and (min-width: 340px) and (max-width: 539px) {
      margin-bottom: -30px;
      width: 60%;
    }

    @media screen and (min-width: 540px) and (max-width: 767px) {
      margin-bottom: -30px;
      width: 60%;
    }

    @media (max-width: $smm) {
      margin-bottom: 20px;
      width: 55%;
    }

    @media (max-width: $sm) {
      margin-bottom: 40px;
      width: 60%;
    }
  }
}

// Footer - Social List
.footerSocial {
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid var(--footer-color);

  @media (min-width: $md) {
    display: flex;
    align-items: center;
  }

  @media (min-width: $lg) {
    padding-top: 3.6rem;
    padding-bottom: 3.2rem;
  }

  .footerHeader & {
    border-bottom: 0;

    .spriteIcon {
      width: 3.6rem;
      height: 3.6rem;
    }
  }
}

.footerSocialList {
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  list-style-type: none;
  height: 2.4rem;
  padding-top: 2.4rem;
  padding-bottom: 1.2rem;

  @media (min-width: $md) {
    padding-left: 1.6rem;
    padding-top: 1.2rem;
    padding-bottom: 1.2rem;
    justify-content: space-between;
  }

  li {
    padding: 0;
    margin-left: 1.6rem;
    margin-bottom: -0.4rem;

    &:first-child {
      margin-left: 0;
    }
  }
}

// Footer - Contact
.footerContact {
  display: flex;
  padding-top: 4rem;
  padding-bottom: 2.4rem;

  @media (min-width: $md) {
    padding-left: 2.4rem;
  }

  p {
    font-family: var(--font-regular);
    font-size: 1.6rem;
    line-height: math.div(24, 16);
    letter-spacing: 0.02em;
  }

  address {
    font-style: normal;
    font-family: var(--font-regular);
    font-size: 1.6rem;
    line-height: math.div(24, 16);
    letter-spacing: 0.02em;

    +p {
      padding-top: 2.8rem;
    }
  }

  .footerHeader & {
    border-bottom: 0;
    color: inherit;
    padding-top: 2.4rem;
    padding-bottom: 1rem;

    address+p {
      padding-top: 1.6rem;
    }
  }
}

// Footer - Base Links and Copy.etc
.footerInfoLinks {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  border-top: 1px solid #ffffff36;
  margin-top: 2.4rem;
}

.footerInfo {
  display: flex;
  font-family: var(--font-regular);
  font-size: 1.4rem;
  font-weight: 500;
  line-height: math.div(24, 16);
  letter-spacing: 0.02em;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
  padding-top: 1.2rem;

  @media (min-width: $lg) {
    padding-top: 0;
    justify-content: flex-end;
  }
}

.footerLogo {
  left: -3.2rem;
  position: relative;
  display: none;

  @media (min-width: $lg) {
    display: block;
  }
}

.footerLinks {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
  padding-top: 2.4rem;
  padding-bottom: 2.4rem;
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 1rem;

  @media (min-width: $lg) {
    flex: 0 0 66.666%;
    max-width: 66.666%;
  }

  a {
    margin-right: 2rem;
    display: inline-flex;
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;

    @media (min-width: $lg) {
      margin-right: 4.4rem;
    }

    font-family: var(--font-regular);
    font-style: normal;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 1.7rem;

    &:last-child {
      margin-right: 0;
    }
  }

  &+p {
    flex: 0 0 100%;
    max-width: 100%;

    @media (min-width: $lg) {
      flex: 0 0 33.333%;
      max-width: 33.333%;
    }
  }

  .footerHeader & {
    margin-left: -.4rem;
  }
}

.footerLinksNews {
  gap: 2.5rem;
}

// Footer - Contact
.footerContactNew {
  padding-top: 4rem;
  display: flex;
  justify-content: space-between;
  // @media (min-width: $md) {
  //   padding-left: 2.4rem;
  // }

  @media (max-width: $md) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  p {
    font-family: var(--font-regular);
    font-size: 1.6rem;
    line-height: math.div(24, 16);
    letter-spacing: 0.02em;
  }

  address {
    font-style: normal;
    font-family: var(--font-regular);
    font-size: 1.6rem;
    line-height: math.div(24, 16);
    letter-spacing: 0.02em;

    +p {
      padding-top: 1.5rem;
    }
  }

  .footerHeader & {
    border-bottom: 0;
    color: inherit;
    padding-top: 2.4rem;
    padding-bottom: 1rem;

    address+p {
      padding-top: 1.6rem;
    }
  }

  .footerLogos {
    width: auto;
    flex-grow: 1;
    @media (max-width: $md) {
      width: 100%;
    }
  }

  .footerContactAddress {
    width: auto;

    @media (max-width: $md) {
      width: 100%;
    }
  }

  .footerContactCol {
    text-align: end;

    @media (max-width: $md) {
      text-align: left;
    }
  }
}

.footerLogoNews {
  position: relative;
  display: flex;
  align-items: center;


  >a {
    margin-left: 0 !important;
  }

  svg {
    width: 5.3rem;
    height: 6.7rem;
  }

  p {
    font-family: var(--font-regular);
    font-size: 1.6rem;
    line-height: 120%;
    max-width: 50%;
    margin-left: 1rem;

    @media (max-width: $md) {
      max-width: 100%;
    }
  }

  @media (min-width: $lg) {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

.footerSocialListNew {
  padding: 0;
  margin: 1.5rem 0;
  display: flex;
  list-style-type: none;
  justify-content: end;

  @media (max-width: $md) {
    justify-content: left;
  }

  @media (min-width: $md) {
    padding-left: 1.6rem;
    justify-content: end;
  }

  li {
    padding: 0;
    margin-left: 3.6rem;

    &:first-child {
      margin-left: 0;
    }
  }
}

.clubsLogo {
  display: none;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 4rem 5rem;
  width: 100%;
  padding-bottom: 2.4rem;

  img {
    height: 7rem;
    width: 7rem;
  }

  @media (min-width: $md) {
    display: flex;
  }

}

.clubsLogoWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 0.5rem;
  cursor: pointer;

  >span {
    font-family: var(--font-regular);
    font-size: 1.2rem;
    line-height: 120%;
    letter-spacing: 0.02em;
    margin-top: 0.5rem;
  }
}

.allClub {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 2rem;
  width: 100%;

  h2 {
    font-family: var(--font-condensed-semibold);
    font-size: 9rem;
    color: var(--footer-color);
  }

  @media (min-width: $md) {
    display: none;
  }
}

.footerDivider {
  width: 100%;
  height: 1px;
  background-color: #ffffff36;
  margin-top: 4rem;
  margin-bottom: 4rem;
  display: block;
}

.footerDividerDesktop {
  display: none;

  @media (min-width: $md) {
    display: block;
  }
}

.footerDividerMobile {
  display: block;
  margin-bottom: 2rem;

  @media (min-width: $md) {
    display: none;
  }
}


.footerPartner {
  display: flex;
  width: 100%;
  margin-top: 6rem;
  flex-wrap: wrap;
  gap: 3rem 8rem;

  >div:last-child {
    flex-grow: 1;
  }

  &Title {
    @include n_baseRegular();
  }

  &LogoWrapper {
    margin-top: 2rem;

    &Multi {
      margin-top: 0.5rem;
      display: flex;
      flex-wrap: wrap;
      gap: 1.6rem 0;
      align-items: flex-end;
    }

    &Vibe {
      height: 70px;
      max-height: unset !important; 
      padding-bottom: 8px;
    }

    &Hr {
      height: 40px;
      max-height: unset !important;
    }

    >img {
      margin-right: 1.5rem;
      max-height: 5.8rem;
    }
  }
}

.desktopOnly {
  display: none !important;

  @media (min-width: $md) {
    display: block !important;
  }
}


.footerNew {
  >.mainContainer {
    @media (max-width: $md) {
      display: none;
    }
  }
}