@use "sass:math";

@import 'Variables';

.cards {
  display: flex;
  flex-wrap: wrap;
  margin-left: -1.6rem;
  margin-right: -1.6rem;
  padding-bottom: 2rem;
}

.cardsByGroup {
  display: flex;
  flex-direction: column;
  margin-left: -1.6rem;
  margin-right: -1.6rem;
  padding-bottom: 2rem;

  &Wrapper {
    padding-left: 1.6rem;
    padding-right: 1.6rem;

    >h2 {
      margin-bottom: 3rem;
    }

    >div {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
    }
  }
}

.cardContainer {
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  flex: 0 0 100%;
  max-width: 100%;
  padding-bottom: 2.4rem;

  @media(min-width: $md) {
    flex: 0 0 50%;
    max-width: 50%;
  }

  @media(min-width: $lg) {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
}

.card {
  position: relative;
  border-radius: 2.1rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  height: 100%;
  aspect-ratio: 3/4.5;
  cursor: pointer;

  @media(max-width: $smm) {
    aspect-ratio: 3/4.5;
  }

  &Title {
    display: block;
    font-family: var(--font-condensed-semibold);
    font-size: 2.4rem;
    line-height: math.div(42, 40);
    letter-spacing: 0.01em;
    color: white;
    text-transform: uppercase;
    &Black {
      color: black;
    }
  }

  &Logo {
    display: flex;
    justify-content: center;
  }

  &Header {
    padding: 2rem 2rem 3.3rem 2rem;
    flex-grow: 1;
    position: absolute;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  &Gradient {
    background: linear-gradient(179.71deg, rgba(0, 0, 0, 0) 42.03%, #000000 99.75%);
    position: absolute;
    bottom: 0;
    height: 100%;
    width: 100%;
  }

  &Image {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;

    >img {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center center;
      // transition: transform 0.35s ease;
      transition: transform 600ms cubic-bezier(0.000, 0.000, 0.580, 1.000);
    }
  }

  &Body {
    padding: 2rem 2rem 3.3rem 2rem;
    flex-grow: 1;
    position: absolute;
    bottom: 0;
    width: 100%;
  }

  &Actions {
    display: flex;
    justify-content: space-between;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 1.6rem;
    padding-bottom: 1.6rem;
    align-items: center;
    border-top: 1px solid var(--light-border);
  }

  &AddressItem {
    display: flex;
    padding-top: 0.8rem;

    span {
      font-family: var(--font-regular);
      font-size: 1.6rem;
      line-height: math.div(24, 16);

      &:first-child {
        margin-right: 0.8rem;
      }
    }
  }

  .button--link {
    text-decoration: underline;
    color: var(--button-3-brand);
    font-size: 1.8rem;
    font-family: var(--font-regular);
  }

  &Tags {
    display: flex;
    flex-wrap: wrap;
    padding-top: 1.8rem;
    gap: 0.8rem 0;
    justify-content: center;
    // margin-left: -1.2rem;
    // margin-right: -1.2rem;
  }

  &Tag {
    font-size: 1.6rem;
    font-family: var(--font-regular);
    padding: 0.4rem 1.1rem 0.5rem 1.1rem;
    margin-top: .6rem;
    margin-bottom: .6rem;
    margin-left: 1.2rem;
    background: var(--dirty-grey);
    border-radius: 4px;
  }

  .button--link {
    position: relative;
    z-index: 100;
  }
}

.card {
  &:hover {
    .cardImage {
      >img {
        transform: scale(1.1);
        transition: transform 0.35s ease;
      }
    }
  }
}

.cardSuckerLink {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 2;
}

.cardHoverContent {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: 100%;

  .cardLogo {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -15%;
    // transform: translateY(90%);
    transition: transform .35s ease;
    height: 100%;

    svg {
      width: 80%;
      height: auto;
      position: absolute;
      bottom: 0;
      left: 0;
    }

    img {
      position: absolute;
      top: auto;
      bottom: 10rem;
      bottom: 38%;
      width: 20%;
      left: 8%;
      height: auto;
      z-index: 10;
    }
  }

  .cardPrimaryAngle {
    position: absolute;
    left: 0;
    bottom: -0.4rem;
    right: 0;
    z-index: 3;
    // transform: translateY(2rem);
    transition: transform 0.35s ease;

    path {
      fill: var(--primary)
    }

    svg {
      display: block;
      width: 94%;
      height: auto;
    }
  }

  .cardSecondaryAngle {
    position: absolute;
    left: auto;
    bottom: -0.4rem;
    right: 0;
    // transform: translateY(2rem);
    transition: transform 0.35s ease;

    path {
      fill: var(--secondary)
    }

    svg {
      display: block;
      width: 92%;
      margin-left: auto;
      height: auto;
    }
  }
}