@use "sass:math";

@import 'Variables';
// @import 'Animations';
@import 'Mixins';
@import 'Type';
@import 'Buttons';
@import 'Cards';
@import 'Layout';

@mixin scroll-bar-news() {
  &::-webkit-scrollbar {
    height: 2px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 5px;
    background-color: #000;
  }

  &::-webkit-scrollbar-track:hover {
    background-color: #000;
  }

  &::-webkit-scrollbar-track:active {
    background-color: #000;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: #fff
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #fff
  }

  &::-webkit-scrollbar-thumb:active {
    background-color: #fff
  }
}


.menuButton {
  color: var(--white);
  border: 0;
  align-items: center;
  position: absolute;
  top: 50%;
  right: 2rem;
  transform: translateY(-50%);
  font-family: var(--font-semibold);
  text-transform: uppercase;
  background-color: rgba(0, 0, 0, 0);
  font-size: 1.6rem;
  cursor: pointer;
  z-index: 1000;
  display: none;

  @media (max-width: $md) {
    display: flex;
  }
}


.menuBurger {
  display: flex;
  flex-direction: column;
  width: 2.2rem;
  height: 1.6rem;
  align-items: center;
  justify-content: space-between;
  margin-right: .9rem;
  margin-bottom: -2px;

  span {
    display: block;
    border-radius: .1rem;
    width: 100%;
    border-top: .2rem solid var(--white);
  }
}


// Header
.header,
.headerClub {
  width: 100%;
  height: 6rem;
  position: relative;
  z-index: 9999;
  background-color: black;
  top: 0;
  left: 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  .mainContainer {
    height: 6rem;
    background-color: var(--body-bg);
    color: var(--body-color);
    display: flex;
    align-items: center;
    max-width: var(--max-width-lg);
  }

  svg:not(.spriteIcon) {
    width: 37px;
    height: 38px;

    @media (min-width: $lg) {
      width: 59px;
      height: 74px;
    }
  }

  .headerLinkContainer {
    margin-left: 3.2rem;

    >h2 {
      padding-left: 1.5rem;
      font-size: 3.8rem;
      border-left: 2px solid #F7F7FA;
      border-radius: 0;
      margin-left: 0;
      font-family: var(--font-condensed-semibold);

      @media (max-width: $lg) {
        font-size: 2.8rem;
      }
    }

    @media (max-width: $md) {
      display: none;
    }

    @media (max-width: $lg) {
      margin-left: 1rem;
    }
  }

  .headerLinksNav {
    align-items: center;

    div:last-child {
      margin-right: 4.4rem;
    }

    a {
      font-family: var(--font-condensed-semibold);
      font-size: 2.1rem;
    }
  }

  .button {
    display: inline-flex;
    align-items: center;
    color: var(--white);
    margin-left: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
    text-transform: uppercase;

    svg {
      margin-bottom: -0.1rem;
    }

    @media (max-width: $md) {
      color: var(--button-3-brand);
    }
  }

  .logoLink {
    top: -2.7rem;
    margin-left: -1rem;

    @media (min-width: $lg) {
      top: -0.2rem;
      margin-left: -1.6rem;
    }
  }

  .headerLogoNews {
    display: flex;
    align-items: center;

    @media (min-width: $md) {
      .headerLinksNav {
        display: none;
      }
    }
  }

  .headerLogo {
    margin-left: 0;

    >.headerLinksNav {
      >.headerLinkContainer {
        display: flex;

        .button {
          color: var(--white);
        }

        h2 {
          font-size: 2.1rem;

          @media (min-width: $sm-md) {
            font-size: 2.1rem;
          }
        }
      }
    }

    @media (min-width: $lg) {
      margin-left: 2.5rem;
    }
  }

  @media (min-width: $lg) {
    height: 9rem;

    .mainContainer {
      height: 9rem;
    }
  }
}

.logoLink {
  position: relative;
  display: block;
}

.headerClub {
  background-color: var(--brand-blue);
  height: 6rem;

  .button {
    color: white;
  }

  .mainContainer {
    height: 6rem;
  }

  .logoLink {
    top: -2.7rem;
  }

  svg:not(.spriteIcon) {
    width: 54px;
    height: 57px;
    margin-bottom: -8.6rem;
  }

  &+main {
    margin-top: 6rem;
  }
}

.headerLinks {
  display: flex;
  width: 100%;
  justify-content: space-between;
  transition: transform 0.5s ease-in-out;
  color: var(--brand-blue);

  @media (max-width: $md) {
    position: fixed;
    right: 0;
    top: 6rem;
    width: 100%;
    bottom: 0;
    background-color: white;
    transform: translateX(100%);
    background-color: var(--dirty-grey);
    padding-top: 4rem;
    display: flex;
    flex-direction: column;
    overflow: auto;
    justify-content: flex-start;
  }

  &Nav {
    display: flex;

    @media (max-width: $md) {
      display: block;

      .button.button--link {
        display: flex;
        justify-content: flex-start;
        width: auto;
        margin-left: 0 !important;
        padding-left: 2rem;
        padding-right: 2rem;
        margin-top: 2rem;
        margin-bottom: 2rem;
        font-family: var(--font-condensed-semibold);
        font-size: 4.6rem;
      }
    }
  }

  .menuNavigationOpen & {
    transform: translateX(0);
  }
}


.headerClub {
  .headerLinks {
    @media (max-width: $md) {
      background-color: var(--brand-blue);
    }
  }

  .headerLinksFooter {
    color: white;
  }

  .menuButton {
    color: white;
  }

  .menuBurger {
    span {
      border-top: .2rem solid white;
    }
  }
}

.headerLinksFooter {
  display: none;

  @media (max-width: $md) {
    display: block;
  }

  .mainContainer {
    display: block !important;
    height: auto !important;
  }

  .spriteIcon {
    width: 3.6rem;
    height: 3.6rem;
  }

  .button--icon {
    margin-left: 0 !important;
  }

  .fontSMHeading {
    display: none;
  }

  .button--none {
    margin-left: 0 !important;
    padding-left: 0 !important;
  }

  .button--link {
    text-transform: none !important;
    margin-left: 0 !important;
    padding-left: 4px !important;
    padding-right: 4px !important;
  }
}

.searchBar {
  background-color: rgba(22, 22, 22, 0.9);
  background-color: rgba(45, 45, 45);
  height: 4.8rem;
  align-items: center;
  display: flex;
  width: 100%;
  position: fixed;
  z-index: 10;
  margin-top: 4.2rem;

  @media (max-width: $lg) {
    margin-top: 1.2rem;
  }

  .mainContainer {
    width: 100%;
    max-width: var(--max-width-lg);
  }
}

.searchBarContainer {
  display: flex;
  align-items: center;
  margin: 0;

  @media (min-width: $xl) {
    margin: 0 4.9rem 0 15.3rem;
  }

  @media (max-width: $lg) {
    overflow: auto;
    margin: 0;
    @include scroll-bar-news();
  }

  .searchDivider {
    width: 1px;
    height: 2.4rem;
    background-color: #999999;
    margin: 0 2.4rem;
  }

  .searchBarInputContainer {
    display: flex;
    // flex-grow: 1;

    .backContainer {
      display: flex;
      align-items: center;
      cursor: pointer;

      &--back {
        display: flex;
        align-items: center;

        >svg {
          color: #fff;
          height: 2.4rem;
          width: 2.4rem;
        }

        >span {
          font-family: var(--font-condensed-semibold);
          font-size: 1.6rem;
          color: #fff;
          margin-left: 0.6rem;
        }
      }

      >h3 {
        font-size: 1.6rem;
        font-family: var(--font-condensed-semibold);
        color: #999999;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }
    }

    @media (max-width: $smm) {
      max-width: 20rem;
    }

    &--is-article {
      @media (max-width: $smm) {
        max-width: 100%;
      }
    }

    >span {
      min-width: 2rem;

      svg {
        color: #fff;
        height: 2rem;
        width: 2rem;
      }
    }

    input {
      margin-left: 1.3rem;
      margin-right: 6rem;
      outline: none;
      background-color: rgba(45 45 45);
      color: #fff;
      border: none;
      font-family: var(--font-condensed-semibold);
      font-size: 1.6rem;
      width: 100%;
      min-width: 15rem;

      @media (max-width: $smm) {
        margin-right: 2rem;
        // max-width: 14rem;
        min-width: 11rem;
      }
    }
  }

  .headerTitleWrapper {
    display: flex;
    // overflow: auto;

    .headerTitle {
      display: flex;

      >a {
        font-size: 1.6rem;
        font-family: var(--font-condensed-semibold);
        color: #999999;
        margin: 0 1.6rem;
        display: flex;
        flex: none;
        align-items: center;
        padding: 12px 0;
        cursor: pointer;
        text-transform: uppercase;
      }

      >a:hover {
        color: #fff;
      }

      >a:first-child {
        margin-left: 0;
      }
    }

  }

}

.main {
  margin-top: 4.8rem;
  padding-bottom: 5rem;

  &NoHeroImage {
    margin-top: 0;
  }
}

.headerContainer {
  position: relative;
  display: flex;
  padding-top: 9rem;
  height: 35rem;
  justify-content: center;

  @media (max-width: $lg) {
    height: 29rem;
    padding-top: 6rem;
  }


  &Image {
    margin-left: auto;
    margin-right: auto;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    >img {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-size: cover;
      object-fit: cover;
      object-position: center center;
      width: 100%;
      height: 100%;
    }
  }
}

.titleHeaderContainer {
  z-index: 1;
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  max-width: var(--max-width-lg);
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 2rem;
  padding-right: 2rem;

  >h1 {
    @include n_heading4();
    margin: 0 4.9rem 0 15.3rem;
    width: 60%;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;

    @media (max-width: $lg) {
      margin: 0;
      width: 100%;
      font-size: 4.2rem;
    }

    @media (max-width: $smm) {
      -webkit-line-clamp: 4;
    }
  }
}

.newsMainCarousel {
  &--navigation {
    display: flex;
    justify-content: center;
    gap: 0 4rem;
    padding-top: 0;

    @media (max-width: $md) {
      padding-top: 4rem;
    }
  }
}

.mainBannerSlider {
  padding-top: 10rem;
  position: relative;
  display: flex;
  justify-content: center;

  &--article {
    @media (min-width: $xl) {
      margin-left: 18rem;
    }
  }

  @media (max-width: $lg) {
    padding-top: 6rem;
  }

  .bannerImage {
    margin: 0 1.2rem;
  }

  .slideContentOverlay {
    position: absolute;
    height: 100%;
    width: 100%;
    bottom: 0;

    &--gradinet {
      background: linear-gradient(90deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.2) 50%, rgba(0, 0, 0, 0) 100%);
    }


    .slideContentContainer {
      margin-left: auto;
      margin-right: auto;
      padding-left: 2rem;
      padding-right: 2rem;
      max-width: var(--max-width-md);
      height: 100%;
      position: relative;
      z-index: 1;
    }

    .slideContentWrapper {
      display: flex;
      flex-direction: column;
      justify-content: end;
      height: 100%;
      padding-bottom: 2.6rem;

      &--mainSlider {
        @media (min-width: $lg) {
          height: calc(100% - 12rem);
        }
      }

      &--thubnailSlider {
        justify-content: flex-start;
      }

      .slideContent {
        display: flex;
        flex-direction: column;
        max-width: 360px;
        position: relative;

        >span:first-child {
          font-size: 1.2rem;
          font-family: var(--font-regular);
          color: #fff;
          margin-bottom: 0.6rem;
          line-height: 100%;
        }

        >h2 {
          font-size: 4rem;
          font-family: var(--font-condensed-semibold);
          color: #fff;
          margin-top: 0.4rem;
          text-transform: uppercase;
          line-height: 87%;
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;

          @media (max-width: $smm) {
            font-size: 3.6rem;
          }
        }

        >p {
          font-size: 1.4rem;
          font-family: var(--font-regular);
          color: #D7D7D7;
          margin-top: 0.6rem;
          line-height: 130%;
          letter-spacing: 0.02em;
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 4;
        }

        >span:last-child {
          margin: 2.4rem 0;

          >.buttonHome {
            font-family: var(--font-condensed-semibold);
            font-style: normal;
            line-height: 90%;
            text-transform: uppercase;
            font-size: 1.8rem;
            letter-spacing: .03em;
            padding: 1rem 2.5rem;
          }
        }
      }

      .thumbGalleryContainer {
        display: flex;
        justify-content: space-between;
        gap: 2.4rem;

        @media (max-width: $lg) {
          display: none;
        }

        &--wrapper {
          display: flex;
          justify-content: flex-start;
          margin-top: 2.4rem;
          margin-bottom: 2.4rem;
          flex-direction: column;
          width: 100%;
          cursor: pointer;

          >span {
            font-size: 1.2rem;
            color: #999999;

            &.isActive {
              color: #fff !important;
            }

            &:first-child {
              font-size: 1.2rem;
              font-family: var(--font-regular);
              color: #999999;
              margin-bottom: 0.6rem;
              line-height: 100%;
            }
          }

          >h3 {
            &.isActive {
              color: #fff !important;
            }

            font-size: 2.1rem;
            font-family: var(--font-condensed-semibold);
            color: #999999;
            margin-bottom: 0.6rem;
            line-height: 98%;
            text-transform: uppercase;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
        }

      }



    }

    .gradient {
      bottom: 0;
      position: absolute;
      height: 50%;
      width: 100%;
      background: linear-gradient(180deg, rgba(15, 15, 15, 0) 0%, #0F0F0F 92%);
      z-index: 0;
    }
  }


  .bannerImageContainer {
    width: 100%;
    height: 100%;
    display: flex;
    max-height: 68rem;

    picture {
      width: 100%;

      >img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 0.4rem;
      }
    }
  }

  &.mainBannerSliderHome {
    padding-top: 0;
  }
}

.mainBannerSliderSubsite {
  padding-top: 5.8rem;
}

.mainBannerSliderSubsiteAboutUs {
  padding-top: 0;
}

.newsSwiperMainContainer {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  max-width: var(--max-width-lg);
  // padding-left: 2rem;
  // padding-right: 2rem;

  @media (max-width: $md) {
    padding-left: 0;
    padding-right: 0;
  }

  .mobilethumbGalleryContainer {

    &--article {
      display: flex;
    }

    @media (max-width: $md) {
      display: flex;
    }

    display: none;
    position: absolute;
    bottom: 45px;
    right: 0;
    height: 44px;
    z-index: 100;
    gap: 8px;
    margin-right: 18px;
    align-items: center;
    justify-content: center;

    >div {
      margin: 0;
      display: flex;
      gap: 4px;
    }


  }
}

.newsMainContainer {
  margin-left: auto;
  margin-right: auto;
  padding-left: 2rem;
  padding-right: 2rem;
  max-width: var(--max-width-md);
  margin-top: 7.1rem;

  @media (max-width: $smm) {
    margin-top: 3.6rem;
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }

  .titleContainer {
    display: flex;
    align-items: baseline;

    @media (max-width: $smm) {
      // margin-left: 1.4rem;
      // margin: 0 2rem;
      justify-content: space-between;
      align-items: center;
    }

    &--newsAndEvents {
      margin-top: 4rem;
    }

    .title {
      font-size: 6.6rem;
      font-family: var(--font-condensed-semibold);
      color: #fff;

      @media (max-width: $smm) {
        font-size: 3.6rem;
      }
    }

    .subTitle {
      font-size: 1.6rem;
      color: #fff;
      margin-left: 1rem;
      font-family: var(--font-regular);
      cursor: pointer;

      @media (max-width: $smm) {
        font-size: 1.4rem;
      }
    }
  }

  .eventCardsContainer {
    position: relative;

    .eventCardsContainerWrapper {
      display: flex;
      overflow-x: auto;
      margin: 0 -0.4rem;
      white-space: nowrap;
      position: relative;

      &::-webkit-scrollbar {
        height: 0px;
      }

      @media (max-width: $smm) {
        // margin-left: 1.4rem;
        margin-top: 2.4rem;
      }

      .eventCard {
        flex: 0 0 auto;
        margin: 0 0.4rem;
        height: 256px;
        width: 164px;
        position: relative;

        &:last-child {
          margin-right: 2.5rem;
        }

        &::before {
          content: "";
          bottom: 0;
          position: absolute;
          height: 50%;
          width: 100%;
          background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
        }

        .eventCardTitleContainer {
          position: absolute;
          margin: 0 1.2rem 1.6rem 1.2rem;
          bottom: 0;

          >div {
            display: flex;
            flex-direction: column;

            >.title {
              font-size: 1.4rem;
              font-family: var(--font-semibold);
              color: #fff;
              margin-bottom: 0.2rem;
              color: #fff;
              white-space: normal;
            }

            >.subTitle {
              font-size: 1.4rem;
              font-family: var(--font-regular);
              color: #fff;
              margin-bottom: 0.2rem;
              white-space: normal;
            }

            >.eventDesc {
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-top: 0.4rem;

              >span {
                font-size: 1.4rem;
                font-family: var(--font-regular);
                color: #D9E1FB;
                white-space: normal;
              }

              &.isPast {
                >span {
                  color: #5080FA !important;
                }
              }
            }
          }
        }
      }

      .eventCardImage {
        >img {
          height: 100%;
          width: 100%;
          object-fit: cover;
          border-radius: 0.4rem;
        }
      }
    }

    .eventCardsContainerGradient {
      right: -5px;
      position: absolute;
      height: 100%;
      width: 42px;
      background: linear-gradient(90deg, rgba(15, 15, 15, 0) 0%, #0F0F0F 40.36%);
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
      top: 0;
    }
  }
}

.swiperthumbsContainer {
  height: 0.4rem;
  width: 1.3rem;
  background-color: #e0e0de;
  border-radius: 5rem;
  margin-bottom: 1.5rem;
  cursor: pointer;

  &--banner {
    width: 100%;
  }

  .swiperthumbsFill {
    height: 100%;
    background-color: #E3001F;
    border-radius: inherit;
    text-align: right;
  }
}

.template__section-header {
  padding-bottom: 3.2rem;
}

// .template__swiper {
//   position: relative;
//   padding-bottom: calc(2.4rem / 2);

//   @media (min-width: $sm-md) {
//     padding-bottom: 4rem;
//   }

//   &-slide {
//     padding-bottom: 3.2rem;
//   }
// }


.template__cards {
  display: flex;
  flex-wrap: wrap;
  margin-left: -1.2rem;
  margin-right: -1.2rem;

  @media (min-width: $xl) {
    margin-left: -2rem;
    margin-right: -2rem;

  }

  &--grid {
    display: flex;

    .template__card-container {
      flex: 0 0 100%;
      max-width: 100%;
      position: relative;

      @media (max-width: $smm) {
        padding-bottom: 0.8rem;
      }

      @media (min-width: $md) {
        flex: 0 0 50%;
        max-width: 50%;
      }

      @media (min-width: $xl) {
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
      }
    }
  }

  &-load-more {
    display: flex;
    width: 100%;
    justify-content: center;
    padding-top: 2.4rem;
  }
}

.template__card-container {
  position: relative;
  flex: 0 0 100%;
  max-width: 100%;
  padding-left: 1.2rem;
  padding-right: 1.2rem;
  padding-bottom: 2.6rem;
  width: 100%;
  height: fit-content;

  &--chip {
    position: absolute;
    top: 1.2rem;
    left: -0.3rem;
    z-index: 1;
    font-size: 1.4rem;
    font-family: var(--font-medium);
    padding: 0.3rem 0.3rem 0.5rem 0.5rem;
    background: #E3001F;
    color: #fff;

    &.is-news,
    &.is-matchReport {
      background: #5080FA;

      >svg {
        color: #5080FA;
      }
    }

    &.is-event {
      background: #E3001F;

      >svg {
        color: #E3001F;
      }
    }

    >svg {
      color: #E3001F;
      right: -15px;
      position: absolute;
      height: 100%;
      top: 0px;
    }
  }

  &--chiplg {
    font-size: 2.2rem;
    line-height: 100%;
    padding: 0.5rem 0.8rem;

    @media (max-width: $smm) {
      font-size: 1.4rem;
      padding: 0.3rem 0.3rem 0.5rem 0.5rem;
    }
  }

  @media (max-width: $smm) {
    display: flex;

    width: 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  @media (min-width: $sm-md) {
    flex: 0 0 50%;
    max-width: 50%;
  }

  @media (min-width: $xl) {
    flex: 0 0 100%;
    max-width: 100%;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  &--related-article {
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }
}

.services-swiper {
  overflow: visible;
}

.services-swiper__slide {
  width: auto !important;
  user-select: none;
}

.template {
  height: 100dvh;
  display: flex;
  flex-direction: column;
  background-color: var(--page-bg);
  position: relative;
  width: 100%;
  transition: transform .5s ease-in-out, border-radius .5s ease;
  transition: transform .5s cubic-bezier(.47, 1.64, .41, .8), border-radius .5s cubic-bezier(.47, 1.64, .41, .8);
  border-radius: 0;
  transform: translateX(0);
  overflow: hidden;
  will-change: transform, border-radius;

  &-container {
    display: flex;
    overflow: hidden;
    background-color: var(--page-bg);
    padding-left: var(--dashboard-sidebar-width);

    @media (max-width: $lg-md) {
      padding-left: 0;
    }

    &--external {
      padding-left: 0;
    }
  }

  &__grid {
    margin-left: -2rem;
    margin-right: -2rem;
    display: flex;
    flex-wrap: wrap;
    margin-top: 2.5rem;

    // overflow-x: hidden;
    &--center {
      justify-content: center;
    }

    &-container {
      width: 100%;
      max-width: 120rem;
      margin-left: auto;
      margin-right: auto;
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }

  &__row {
    margin-left: -2rem;
    margin-right: -2rem;
    display: flex;
    flex-wrap: wrap;
  }

  &__column {
    padding-left: 2rem;
    padding-right: 2rem;
    width: 100%;
    flex: 0 0 100%;
    max-width: 100%;
    cursor: pointer;

    &--1-sm {
      width: 100%;
      max-width: 39.2rem;
    }

    &--1-2 {
      @media (min-width: $sm-md) {
        flex: 0 0 50%;
        max-width: 50%;
      }
    }

    &--1-3 {
      @media (min-width: $xl) {
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
      }

      @media (max-width: $smm) {
        margin-top: 2rem;
        // margin-top: 4rem;
        flex: 0 0 100%;
        max-width: 100%;

        >.template__cards {
          flex-wrap: nowrap;
        }
      }

    }

    &--2-3 {
      @media (min-width: $sm-md) {
        flex: 0 0 50%;
        max-width: 50%;
      }

      @media (min-width: $xl) {
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
      }
    }

    &--2-1 {
      @media (min-width: $sm-md) {
        flex: 0 0 50%;
        max-width: 50%;
      }

      @media (min-width: $xl) {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }

    &--3x2 {
      @media (min-width: $xl) {
        flex: 0 0 66.666666%;
        max-width: 66.666666%;
      }

      @media (max-width: $xl) {
        margin-bottom: 2rem;
      }

    }

    &--desktop-none {
      @media (min-width: $xl) {
        display: none;
      }
    }

    &--1-in-1 {
      @media (min-width: $sm-md) {
        flex: 0 0 63.333333%;
        max-width: 63.333333%;
        margin-left: auto;
        margin-right: auto;
      }
    }

    @media (max-width: $smm) {
      padding-left: 1.6rem;
      padding-right: 1.6rem;
    }
  }
}

@mixin articleImage {
  position: absolute;
  inset: 0;
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
  border-radius: 0.4rem;

  @media (max-width: $smm) {
    max-width: 125px;
    min-width: 125px;
    height: 100%;
    max-height: 100px;
    min-height: 100px;
    border-radius: 4px;
    margin-right: 14px;
    object-fit: cover;
    position: unset;
  }
}

.newsCard {

  @media (max-width: $smm) {
    width: 100%;
  }

  &__image {
    position: relative;

    &--wrapper {
      aspect-ratio: 3 / 2;
      position: relative;

      >img {
        position: absolute;
        inset: 0;
        object-fit: cover;
        object-position: center;
        width: 100%;
        height: 100%;
        border-radius: 0.4rem;
      }
    }

    &--sm {
      aspect-ratio: 3 / 1.8;
    }


    >img {
      width: 100%;
      border-radius: 4px;
    }
  }

  &__images {
    position: relative;

    &--wrapper {
      aspect-ratio: 3 / 2;
      position: relative;

      >img {
        @include articleImage();
      }

      picture {
        >img {
          @include articleImage();
        }
      }
    }
  }


  &__content {
    display: flex;
    flex-direction: column;

    &--type {
      font-size: 1.4rem;
      font-family: var(--font-regular);
      color: #5080FA;
      margin-top: 0.8rem;
    }

    &--title {
      font-size: 4rem;
      font-family: var(--font-condensed-semibold);
      color: #fff;
      line-height: 98%;
      margin-top: 0.6rem;
    }

    &--bigCard {
      line-height: 87%;
    }

    &--titlesm {
      font-size: 2.4rem;
      margin-top: 0.8rem;

      @media (max-width: $smm) {
        font-size: 2rem;
      }
    }

    &--full {
      @media (max-width: $smm) {
        margin-top: 0
      }
    }

    &--desc {
      font-size: 1.6rem;
      font-family: var(--font-regular);
      color: #D7D7D7;
      margin-top: 0.8rem;
      line-height: 120%;
      letter-spacing: 0.02em;

      @media (max-width: $smm) {
        font-size: 1.4rem;
      }
    }

    &--tags {
      display: flex;
      flex-wrap: wrap;
      margin-top: 2rem;
      gap: 0.5rem;

      >span {
        background-color: #F7F7FA;
        border-radius: 0.3rem;
        border: 1px solid #D1D1D1;
        font-size: 1rem;
        font-family: var(--font-regular);
        color: #000000;
        padding: 0.3rem 0.5rem;
      }
    }

    &--tagssm {
      margin-top: 0.8rem;
    }

    &--date {
      font-size: 1.4rem;
      font-family: var(--font-regular);
      color: #999999;
      margin-top: 0.8rem;
    }
  }
}

.dropdownOverlay {
  position: absolute;
  z-index: 9;
  display: none;
  width: 100%;
  top: 7.5rem;

  &.isVisible {
    display: flex;
  }

  .dropdownContent {
    background: #fff;
    display: flex;
    padding: 2.3rem 2.6rem;
    max-width: 1010px;
    border-radius: 0.4rem;
    position: relative;
    padding-top: 3rem;

    @media (max-width: $smm) {
      padding: 1.6rem;
    }

    .dropdownSection {
      &--mens {
        width: 100%;

        .clubLists {
          &--container {
            flex: 0 0 25%;
          }
        }
      }

      // &--womens {
      //   max-width: calc((100% / 5)* 2);

      //   .clubLists {
      //     &--container {
      //       flex: 0 0 50%;
      //     }
      //   }
      // }

      .sectionTitle {
        font-family: var(--font-condensed-semibold);
        font-size: 1.6rem;
        margin-bottom: 0.5rem;
        color: #B8B8B8;
        line-height: 18px;
        text-transform: uppercase;
      }

      .teamList {
        list-style: none;
        padding: 0;
      }

      .clubLists {
        display: flex;
        flex-wrap: wrap;

        &--container {
          display: flex;
          gap: 1rem;
          margin-bottom: 0.9rem;
          align-items: center;
          cursor: pointer;

          >img {
            height: 2rem;
            width: auto;
          }
        }

        &--item {
          flex-grow: 1;
          display: flex;
          justify-content: space-between;

          &--text {
            font-size: 1.6rem;
            font-family: var(--font-condensed-semibold);
            color: #000000;
            margin-right: 1.5rem;
            text-transform: uppercase;
          }

          &--text:hover {
            color: #B8B8B8;
          }

          &--check {
            display: flex;
            align-items: center;
            margin-right: 1.5rem;

            >svg {
              height: 1.6rem;
              width: 1.6rem;
            }
          }
        }



      }
    }
  }

  &--filter {
    @media (max-width: $smm) {
      left: 0;

      .filterContent {
        width: 100%;
      }
    }
  }

  &--clear-filter {
    position: absolute;
    right: 1.5rem;
    top: 1rem;
    font-size: 1.6rem;
    font-style: var(--font-semibold);
    color: #000;
    cursor: pointer;

  }

  .filterContent {
    min-width: 30rem;
    background: #fff;
    display: flex;
    border-radius: 0.4rem;
    flex-direction: column;
    padding: 1rem 0;
    gap: 2rem;
    position: relative;

    .filterSection {
      display: flex;
      flex-direction: column;

      // gap: 3rem;
      &--row {
        display: flex;
        align-items: center;
        justify-content: space-between;

        svg {
          margin-right: 2rem;
        }
      }

      &--row:hover {
        background: #2d2d2d5e;
        color: #c1bab8 !important;
      }

      &--title {
        font-family: var(--font-condensed-semibold);
        font-size: 2.1rem;
        color: #B8B8B8;
        text-transform: uppercase;
        padding: 0.5rem 2rem;
      }

      &--content {
        color: #222222;
      }


    }
  }

  .dropdownTab {
    display: flex;
    flex-direction: column;

    &--header {
      margin-bottom: 2rem;
    }

    &--content {
      display: flex;
      flex-wrap: wrap;
      gap: 2.4rem;
      max-height: calc(100vh - 192px);
      overflow: auto;

      .clubLists {
        &--container {
          width: 100%;
        }
      }
    }

    .sectionTitle {
      margin-right: 2.4rem;
    }

    &--isActiveTab {
      color: #000 !important;
      padding-bottom: 0.5rem;
      border-bottom: 2px solid #E3001F;

    }
  }

}

.eventFilterTagContainer {
  display: flex;
  justify-content: flex-start;
  position: relative;
  margin-top: 3rem;

  @media (max-width: $md) {
    margin-top: 4rem;
  }

  .eventFilterTagWrapper {
    display: flex;
    overflow: auto;
    gap: 0.8rem;
    padding: 1rem 0;
    align-items: center;

    .eventFilterTag {
      font-size: 2.1rem;
      font-family: var(--font-condensed-semibold);
      color: #fff;
      background-color: #222222;
      border-radius: 5rem;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      display: flex;

      @media (max-width: $smm) {
        font-size: 1.4rem;
        padding: 0.8rem 1.6rem;
      }

      @media (max-width: $sm) {
        padding: 0.4rem 0.6rem;
      }

      &--count {
        min-width: 25px;
        min-height: 25px;
        // height: 22px;
        // width: 25px;
        background-color: black;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 13px;
        font-size: 1.6rem;
        font-family: var(--font-condensed-semibold);

        @media (max-width: $smm) {
          font-size: 1.2rem;
          min-width: 1.8rem;
          min-height: 1.8rem;
        }
      }

      &--filters {
        padding: 1.6rem;

        @media (max-width: $smm) {
          >svg {
            width: 1.6rem;
            height: 1.6rem;
          }

          align-items: center;
          display: flex;
          padding: 0.6rem;
        }
      }

      >span {
        width: 84%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 1.6rem 1.6rem;
        text-transform: uppercase;

        @media (max-width: $smm) {
          padding: 0.6rem .6rem;
        }
      }

      &.isActive {
        background-color: #F7F7FA;
        color: #000;
      }

      &.dropdown {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 32rem;

        @media (max-width: $smm) {
          max-width: 14rem;
        }

      }
    }

    &--clear-filter {
      margin-left: 1rem;
      font-size: 1.6rem;
      font-style: var(--font-semibold);
      color: #fff;
      cursor: pointer;
    }

    @include scroll-bar-news();
  }
}

// To show the dropdown, toggle this class with JavaScript
.is-visible {
  display: block;
}

.newsArticleMainContainer {
  margin-left: auto;
  margin-right: auto;
  padding-left: 2rem;
  padding-right: 2rem;
  max-width: var(--max-width-md);
  margin-top: 5rem;

  @media (max-width: $smm) {
    border-radius: 20px;
    margin-top: -17px;
    position: relative;
    background: #0F0F0F;
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }

  @media (max-width: $smm) {
    // margin-top: 3.6rem;
    margin-top: -15px;
    padding-top: 2.5rem;
    border-radius: 20px;
    z-index: 2;
    position: relative;
    background-color: #0F0F0F;
  }

  .articleContainer {
    display: flex;
    width: 100%;

    @media (max-width: $sm-md) {
      flex-direction: column;
    }

    &SubSite {
      justify-content: center;
    }
  }

  .articleContainer-mobile {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.7rem;

    .articleContainer-sideBar--date {
      margin-top: 0;
    }
  }

  .articleContainer-sideBar {
    max-width: 28rem;
    width: auto;
    flex-grow: 1;

    @media (max-width: $sm-md) {
      max-width: 100%;
      width: 100%;
    }

    &--container {
      padding: 3rem 2.5rem;
      border: 1px solid #454545;

      @media (max-width: $sm-md) {
        margin-top: 2rem;

        >div {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }
      }
    }

    &--title {
      font-size: 1.6rem;
      font-family: var(--font-regular);
      color: #5080FA;
      margin-bottom: 1.2rem;
      line-height: 150%;

      @media (max-width: $sm-md) {
        margin-bottom: 0;
        min-width: 35%;
        display: flex;
        align-items: center;
      }
    }

    &--desc {
      font-size: 1.6rem;
      font-family: var(--font-regular);
      color: #FFFFFF;
      line-height: 150%;
      letter-spacing: 0.02em;
      margin-top: 1rem;

      @media (max-width: $sm-md) {
        margin-top: 0;
        text-align: right;
      }
    }

    &--icons {
      >div {
        display: flex;
        align-items: center;
        margin-top: 1rem;
        cursor: pointer;

        >svg {
          margin-right: 0.5rem;
          color: #FFFFFF;
        }

        >span {
          margin-top: 0;
        }
      }

    }

    &--shareArticle {
      display: flex;
      flex-direction: column;
      margin-top: 2.5rem;

      @media (max-width: $sm-md) {
        flex-direction: row;
        align-items: center;
        width: 100%;
        justify-content: space-between;
      }

      >span {
        font-size: 1.6rem;
        font-family: var(--font-condensed-semibold);
        color: #FFFFFF;
        margin-bottom: 1.5rem;

        @media (max-width: $sm-md) {
          margin-bottom: 0;
          margin-right: 1.5rem;
        }
      }

      &--container {
        cursor: pointer;
        display: flex;
        flex-direction: column;

        @media (max-width: $sm-md) {
          flex-direction: row;
        }

        >a {
          display: flex;
          align-items: center;
          margin-bottom: 2.1rem;
          position: relative;

          @media (max-width: $sm-md) {
            margin-bottom: 0;
          }

          >svg {
            margin-right: 1.2rem;
            color: #FFFFFF;
          }

          >span {
            font-size: 1.4rem;
            font-family: var(--font-regular);
            color: #999999;
            position: relative;

            @media (max-width: $sm-md) {
              display: none;
            }
          }
        }
      }


    }

    &--date {
      font-size: 1.4rem;
      font-family: var(--font-regular);
      color: #999999;
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;
    }

    &--type {
      font-size: 1.4rem;
      font-family: var(--font-regular);
      color: #5080FA;
      margin-bottom: 1.5rem;
    }

    &--wrapper {
      @media (max-width: $sm-md) {
        display: flex;
        flex-direction: column;
        text-align: end;
      }
    }
  }

  .articleContainer-content {
    max-width: calc(100% - 28rem);
    flex: 1;
    margin-left: 5rem;
    display: flex;
    flex-direction: column;

    @media (max-width: $sm-md) {
      margin-left: 0rem;
      width: 100%;
      max-width: 100%;
    }

    &--title {
      font-size: 6.4rem;
      font-family: var(--font-condensed-semibold);
      line-height: 87%;
      text-transform: uppercase;
      color: #fff;
      margin-top: 1.2rem;

      @media (max-width: $sm-md) {
        font-size: 4.2rem;
      }
    }

    &--desc {
      font-size: 1.8rem;
      font-family: var(--font-regular);
      color: #B8B8B8;
      letter-spacing: 0.01em;
      line-height: 120%;
      margin-top: 1.2rem;

      @media (max-width: $smm) {
        font-size: 1.6rem;
      }
    }

    &--content {
      margin-top: 1rem;
      margin-bottom: 1rem;

      img {
        width: 100%;
      }

      iframe {
        width: 100%;
        height: auto;
        aspect-ratio: 16 / 9;
      }

      h2 {
        font-family: var(--font-semibold);
        font-size: 3rem;
        color: #FFFFFF;
        margin-top: 3.8rem;
        margin-bottom: 2.1rem;
        line-height: 32px;

        @media (max-width: $smm) {
          font-size: 2.6rem;
        }
      }

      p {
        font-family: var(--font-regular);
        font-size: 2rem;
        line-height: 120%;
        color: #FFFFFF;
        padding: 0.5rem 0;

        @media (max-width: $smm) {
          font-size: 1.6rem;
        }
      }

      h3 {
        font-family: var(--font-regular);
        font-size: 2.4rem;
        color: #FFFFFF;
        font-weight: normal;
        padding: 1.5rem 0;
        line-height: 28px;

        @media (max-width: $smm) {
          font-size: 2rem;
        }
      }

      h4 {
        font-family: var(--font-regular);
        font-size: 2rem;
        color: #FFFFFF;
        font-weight: normal;
        padding: 1.5rem 0;
        line-height: 150%;

        @media (max-width: $smm) {
          font-size: 1.6rem;
        }
      }

      blockquote {
        font-family: var(--font-condensed-semibold);
        font-size: 4.2rem;
        color: #FFFFFF;
        margin: 4rem 19%;
        text-align: center;
        text-transform: uppercase;
        line-height: 104%;

        @media (max-width: $smm) {
          margin: 3rem 2rem;
        }
      }

      blockquote::before {
        content: url("data:image/svg+xml;charset=UTF-8,%3csvg width='18' height='15' viewBox='0 0 18 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M8.23186 6.976V14.144H0.359863V7.232C0.359863 2.368 2.66386 0.127998 8.23186 0V3.712C5.73586 3.776 4.71186 4.48 4.64786 6.976H8.23186ZM17.6399 6.976V14.144H9.76786V7.232C9.76786 2.368 12.0719 0.127998 17.6399 0V3.712C15.1439 3.776 14.1199 4.48 14.0559 6.976H17.6399Z' fill='%23E3001F'/%3e%3c/svg%3e ");
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
      }

      blockquote::after {
        content: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='18' height='15' viewBox='0 0 18 15' fill='none'%3e%3cpath d='M9.76786 7.31156V0.143555H17.6399V7.05556C17.6399 11.9196 15.3359 14.1596 9.76786 14.2876V10.5756C12.2639 10.5116 13.2879 9.80756 13.3519 7.31156H9.76786ZM0.359863 7.31156V0.143555H8.23186V7.05556C8.23186 11.9196 5.92786 14.1596 0.359863 14.2876V10.5756C2.85586 10.5116 3.94386 9.80756 3.94386 7.31156H0.359863Z' fill='%23E3001F'/%3e%3c/svg%3e");
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 10px;
      }

      // comment this for now and going to use in future
      li {
        // font-family: var(--font-regular);
        // font-size: 2.4rem;
        color: #FFFFFF;
        margin: 0 3rem;

        // @media (max-width: $smm) {
        //   font-size: 2rem;
        // }
      }

      table {
        color: white;
      }
    }

    &--quote {
      margin-top: 1rem;
      margin-bottom: 1rem;

      p {
        font-family: var(--font-regular);
        font-size: 2rem;
        line-height: 150%;
        color: #FFFFFF;
        word-wrap: break-word;
      }


      blockquote {
        font-family: var(--font-condensed-semibold);
        font-size: 4.2rem;
        color: #FFFFFF;
        margin: 4rem 19%;
        text-align: center;
        text-transform: uppercase;
        line-height: 104%;

        @media (max-width: $smm) {
          margin: 3rem 2rem;
        }
      }

      blockquote::before {
        content: url("data:image/svg+xml;charset=UTF-8,%3csvg width='18' height='15' viewBox='0 0 18 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M8.23186 6.976V14.144H0.359863V7.232C0.359863 2.368 2.66386 0.127998 8.23186 0V3.712C5.73586 3.776 4.71186 4.48 4.64786 6.976H8.23186ZM17.6399 6.976V14.144H9.76786V7.232C9.76786 2.368 12.0719 0.127998 17.6399 0V3.712C15.1439 3.776 14.1199 4.48 14.0559 6.976H17.6399Z' fill='%23E3001F'/%3e%3c/svg%3e ");
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
      }

      blockquote::after {
        content: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='18' height='15' viewBox='0 0 18 15' fill='none'%3e%3cpath d='M9.76786 7.31156V0.143555H17.6399V7.05556C17.6399 11.9196 15.3359 14.1596 9.76786 14.2876V10.5756C12.2639 10.5116 13.2879 9.80756 13.3519 7.31156H9.76786ZM0.359863 7.31156V0.143555H8.23186V7.05556C8.23186 11.9196 5.92786 14.1596 0.359863 14.2876V10.5756C2.85586 10.5116 3.94386 9.80756 3.94386 7.31156H0.359863Z' fill='%23E3001F'/%3e%3c/svg%3e");
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 10px;
      }
    }


    .articleButton {
      display: flex;
      justify-content: center;

      >a {
        font-size: 2.2rem;
        margin: 2.4rem 0;
        padding: 1rem 4rem;

        @media (max-width: $smm) {
          width: 100%;
        }
      }
    }
  }

  .articleContainer-pagination {
    display: flex;
    justify-content: center;
    width: 100%;
    // margin-bottom: 3rem;

    &--progress {
      width: 90px;
      gap: 6px;
      display: flex;
      justify-content: center;
    }

  }

  .articleContainer-divider {
    &--s1 {
      margin: 2.3rem 0;
      border: 1px solid #454545;
    }

    &--s2 {
      margin: 3rem 0;
      border: 1px solid #454545;
    }
  }

  .articleContainer-countDown {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 2rem 3rem;
    justify-content: space-between;

    @media (max-width: $smm) {
      margin: 2rem;
    }

    >div {
      display: flex;
      flex-direction: column;
      align-items: center;

      >span:first-child {
        font-family: var(--font-condensed-semibold);
        font-size: 9rem;
        line-height: 100%;
        color: #454545;

        @media (max-width: $sm-md) {
          font-size: 5.3rem;
        }
      }

      >span:last-child {
        font-family: var(--font-regular);
        font-size: 3.2rem;
        line-height: 130%;
        color: #B8B8B8;

        @media (max-width: $sm-md) {
          font-size: 1.8rem;
        }
      }
    }
  }

  .articleContainer-similerEvents {
    &--title {
      font-size: 4.5rem;
      font-family: var(--font-condensed-semibold);
      color: #FFFFFF;
      margin-top: 3.2rem;
      margin-bottom: 2.4rem;
      text-transform: uppercase;
    }
  }


}

.template__swiper {
  position: relative;
  padding-bottom: 2rem;
  padding-top: 1rem;

  @media (min-width: $sm-md) {
    padding-bottom: 2rem;
  }

  &-slide {
    padding-bottom: 10.8rem;
  }
}

.learn-swiper {

  .swiper-button-next,
  .swiper-button-prev {
    margin-top: -7.1rem;
  }

  .swiper-pagination {
    left: 0 !important;
    transform: none !important;
    width: 40px !important;
    bottom: 0px !important;
    overflow: visible !important;
  }

  .swiper-pagination-bullet {
    left: 0 !important;

    &-active {
      background-color: var(--brand-blue);
    }
  }
}


.showInMobile {
  display: none;

  @media (max-width: $sm-md) {
    display: block;
  }
}

.showInMobile-flex {
  display: none;

  @media (max-width: $sm-md) {
    display: flex;
  }
}

.hideInMobile {
  display: block;

  @media (max-width: $sm-md) {
    display: none;
  }
}


.newsListContainer {
  margin-left: auto;
  margin-right: auto;
  padding-left: 2rem;
  padding-right: 2rem;
  max-width: var(--max-width-md);
  padding: 5rem 2.5rem;
  padding-top: 9rem;

  @media (max-width: $smm) {
    padding-top: 2.6rem;
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }
}

.loadMore {
  display: flex;
  justify-content: center;
  margin-top: 2.4rem;
}

.isLoadingContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 2.1rem;
  font-family: var(--font-condensed-semibold);
  color: #FFFFFF;
  margin: 20%;
  gap: 1.2rem;
  text-transform: uppercase;
}

.copyClipboard {
  position: absolute;
  top: -5px;
  right: -70px;
  background: white;
  padding: 6px;
  color: black;
  border-radius: 6px;
  font-family: var(--font-regular);

  &--mobile {
    top: 3rem;
    right: 0rem;
    font-size: 1.4rem;
  }
}