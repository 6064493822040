@import 'Variables';

.mainContainer {
  @include main-container();
  max-width: var(--max-width);
}

.mainContainerSm {
    max-width: var(--max-width-sm);
    @include main-container();
}

.mainContainerMd {
  max-width: var(--max-width-md);
  @include main-container();
}

.main-layout,
.mainLayout {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 100vh;
  font-family: var(--font-regular);
}

.main {
  flex-grow: 1;
}

.homeMain {
  background-color: var(--brand-blue);
}

.row {
  @include row();
}

.col {
  @include col();
}

.colFull {
  @include col();
  width: 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.col6 {
  @include col();
  flex: 0 0 50%;
  max-width: 50%;
}

.col6md {
  @include col();
  flex: 0 0 100%;
  max-width: 100%;
  @media (min-width: $md) {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.col6lg {
  @include col();
  flex: 0 0 100%;
  max-width: 100%;
  @media (min-width: $lg) {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.col6md {
  @include col();
  flex: 0 0 100%;
  max-width: 100%;
  @media (min-width: $md) {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.col5lg {
  @include col();
  flex: 0 0 100%;
  max-width: 100%;
  @media (min-width: $md) {
    flex: 0 0 50%;
    max-width: 50%;
  }
  @media (min-width: $lg) {
    flex: 0 0 calc((100% / 12) * 5);
    max-width: calc((100% / 12) * 5);
  }
}

.col4lg {
  @include col();
  flex: 0 0 100%;
  max-width: 100%;
  @media (min-width: $md) {
    flex: 0 0 50%;
    max-width: 50%;
  }
  @media (min-width: $lg) {
    flex: 0 0 calc((100% / 12) * 4);
    max-width: calc((100% / 12) * 4);
  }
}

.col3lg {
  @include col();
  flex: 0 0 100%;
  max-width: 100%;
  @media (min-width: $md) {
    flex: 0 0 50%;
    max-width: 50%;
  }
  @media (min-width: $lg) {
    flex: 0 0 calc((100% / 12) * 3);
    max-width: calc((100% / 12) * 3);
  }
}

.colForm3lg {
  @include col();
  flex: 0 0 100%;
  max-width: 100%;
  @media (min-width: $lg) {
    flex: 0 0 calc((100% / 12) * 3);
    max-width: calc((100% / 12) * 3);
  }
}

.colForm2lg {
  @include col();
  flex: 0 0 100%;
  max-width: 100%;
  @media (min-width: $lg) {
    flex: 0 0 calc((100% / 12) * 2);
    max-width: calc((100% / 12) * 2);
  }
}

.col1lg {
  @include col();
  display: none;
  @media (min-width: $lg) {
    display: block;
    flex: 0 0 calc((100% / 12) * 1);
    max-width: calc((100% / 12) * 1);
  }
}

.col2lg {
  @include col();
  display: none;
  @media (min-width: $lg) {
    display: block;
    flex: 0 0 calc((100% / 12) * 2);
    max-width: calc((100% / 12) * 2);
  }
}

.col8lg {
  @include col();
  flex: 0 0 100%;
  max-width: 100%;
  @media (min-width: $lg) {
    display: block;
    flex: 0 0 calc((100% / 12) * 8);
    max-width: calc((100% / 12) * 8);
    margin-left: auto;
    margin-right: auto;
  }
}