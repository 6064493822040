@use "sass:math";

@import 'Variables';

@mixin font-sm() {
    font-family: var(--font-regular);
    font-size: 1.6rem;
    line-height: math.div(24, 16);
    letter-spacing: 0.02em;
}

@mixin font-heading() {
    font-family: var(--font-condensed-semibold);
    font-size: 7rem;
    line-height: math.div(100, 70);

    .form & {
        text-transform: uppercase;
        margin-bottom: 0.25em;
    }
}

@mixin font-heading-3() {
    margin-bottom: 0.25em;
    font-family: var(--font-regular);
    font-style: normal;
    font-weight: 400;
    font-size: 3.6rem;
    line-height: math.div(42, 36);
    letter-spacing: 0.015em;
    color: #000000;
}

@mixin font-heading-sm() {
    margin-bottom: .6rem;
    color: #000000;
    font-family: var(--font-regular);
    font-weight: 400;
    font-size: 2.4rem;
    line-height: math.div(36, 24);
}

@mixin font-display() {
    font-family: var(--font-condensed-semibold);
    font-size: 4.2rem;
    font-size: clamp(4.2rem, 10vw, 6.4rem);
    text-transform: uppercase;

    @media (min-width: $md) {
        font-size: 12rem;
        font-size: clamp(4.2rem, 10vw, 12rem);
        line-height: calc(138/120);
        letter-spacing: 0.015em;
    }
}

@mixin font-page-title() {
    font-family: var(--font-condensed-semibold);
    font-size: 4.2rem;
    line-height: math.div(48, 42);
    text-transform: uppercase;
    color: white;

    @media (min-width: $md) {
        font-size: 9rem;
        line-height: math.div(135, 90);
    }
}

@mixin input() {
    appearance: none;
    padding: 1rem 1.4rem;
    background-color: var(--bg-color);
    border-radius: var(--border-radius);
    outline: 0;
    border: 1px solid var(--input-border);
    font-family: var(--font-regular-italic);
    font-size: 1.6rem;
    width: 100%;

    &--lg {
        @media (min-width: $md) {
            font-size: 2rem;
            padding: 1.8rem 2rem;
        }
    }

    @media (min-width: $md) {
        padding: 1.3rem 1.6rem;
    }
}

@mixin label() {
    display: block;
    margin-bottom: 0.6rem;
    color: black;
}

@mixin select() {
    @include input();
    padding-right: 3.4rem !important;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M6.175 7.15801L5 8.33301L10 13.333L15 8.33301L13.825 7.15801L10 10.9747L6.175 7.15801Z' fill='%23222222'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: center right 0.8rem;
    cursor: pointer;
}

@mixin selectNew() {
    @include input();
    font-family: var(--font-semibold);
    border-radius: 12px;
    border: none;
    cursor: pointer;
}

@mixin textarea() {
    resize: none;
    @include input();
    width: 100%;
    height: 17.6rem;
}

@mixin button {
    display: inline-flex;
    padding: var(--button-padding-y-sm) var(--button-padding-x-sm);
    font-family: var(--font-semibold);
    font-size: 1.6rem;
    letter-spacing: 0.015em;
    border-radius: var(--button-border-radius);
    line-height: math.div(18, 16);
    cursor: pointer;

    @media (min-width: 1023px) {
        padding: var(--button-padding-y) var(--button-padding-x);
        font-size: 2.4rem;
        line-height: math.div(28, 24);
    }

    &--larger-at-mobile {
        @media (max-width: 1023px) {
            font-size: 1.8rem;
            line-height: math.div(21, 18);
            letter-spacing: 0.015em;
            font-family: var(--font-semibold);
            padding: 1.4rem 2.4rem;
        }
    }
}

@mixin main-button-hover() {
    background-color: var(--main-button-hover-bg);
    border-color: var(--main-button-hover-bg);
    color: white;
    box-shadow: 0px 0px 8px rgba(227, 0, 31, 0.2);
}

@mixin row {
    display: flex;
    flex-wrap: wrap;
    margin-left: calc(var(--gutter) * -1);
    margin-right: calc(var(--gutter) * -1);
}

@mixin col {
    padding-left: var(--gutter);
    padding-right: var(--gutter);
}

@mixin background-image() {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    object-fit: cover;
    object-position: center center;
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: 100%;
}

@mixin main-container() {
    margin-left: auto;
    margin-right: auto;
    padding-left: 2rem;
    padding-right: 2rem;
}

@mixin n_heading1 {
    font-family: var(--font-condensed-semibold);
    font-style: normal;
    font-weight: 600;
    font-size: 16rem;
    line-height: 70%;
}


@mixin n_heading2 {
    font-family: var(--font-condensed-semibold);
    font-style: normal;
    font-weight: 600;
    font-size: 10rem;
    line-height: 80%;
}

@mixin n_heading3 {
    font-family: var(--font-condensed-semibold);
    font-style: normal;
    font-weight: 600;
    font-size: 7.2rem;
    line-height: 90%;
}

@mixin n_heading4 {
    font-family: var(--font-condensed-semibold);
    font-style: normal;
    font-weight: 600;
    font-size: 6rem;
    line-height: 90%;
    letter-spacing: -0.01em;
}

@mixin n_heading5 {
    font-family: var(--font-condensed-semibold);
    font-style: normal;
    font-weight: 600;
    font-size: 4.4rem;
    line-height: 90%;
    letter-spacing: -0.01em;
}

@mixin n_heading5_5 {
    font-family: var(--font-condensed-semibold);
    font-style: normal;
    font-weight: 600;
    font-size: 3.1rem;
    line-height: 90%;
    letter-spacing: -0.01em;
}

@mixin n_heading6 {
    font-family: var(--font-condensed-semibold);
    font-style: normal;
    font-weight: 600;
    font-size: 2.1rem;
    line-height: 90%;
}

@mixin n_heading7 {
    font-family: var(--font-condensed-semibold);
    font-style: normal;
    font-weight: 600;
    font-size: 1.8rem;
    line-height: 90%;
}

@mixin n_heading8 {
    font-family: var(--font-condensed-semibold);
    font-style: normal;
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 90%;
}

@mixin n_heading9 {
    font-family: var(--font-condensed-semibold);
    font-style: normal;
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 90%;
    letter-spacing: -0.01em;
}

@mixin n_heading10 {
    font-family: var(--font-condensed-semibold);
    font-style: normal;
    font-weight: 600;
    font-size: 3.2rem;
    line-height: 90%;
    letter-spacing: -0.01em;
}



@mixin n_xLargeRegular {
    font-family: var(--font-regular);
    font-style: normal;
    font-weight: 400;
    font-size: 2.1rem;
    line-height: 160%;
}

@mixin n_mediumRegular {
    font-family: var(--font-regular);
    font-style: normal;
    font-weight: 400;
    font-size: 1.8rem;
    line-height: 140%;
}


@mixin n_baseRegular {
    font-family: var(--font-regular);
    font-style: normal;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 140%;
}

@mixin n_smallRegular {
    font-family: var(--font-regular);
    font-style: normal;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 140%;
}

@mixin n_xLargeBold {
    font-family: var(--font-medium);
    font-style: normal;
    font-weight: 400;
    font-size: 2.1rem;
    line-height: 160%;
}

@mixin n_largeBold {
    font-family: var(--font-medium);
    font-style: normal;
    font-weight: 600;
    font-size: 1.8rem;
    line-height: 160%;
}

@mixin n_baseBold {
    font-family: var(--font-medium);
    font-style: normal;
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 140%;
}

@mixin n_smallBold {
    font-family: var(--font-medium);
    font-style: normal;
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 140%;
}

@mixin n_button {
    display: inline-flex;
    padding: 1.4rem 2.4rem;
    font-family: var(--font-condensed-semibold);
    font-style: normal;
    font-weight: 600;
    font-size: 1.8rem;
    line-height: 1rem;
    border-radius: 0.5rem;
    cursor: pointer;
}