@use "sass:math";

@import 'Variables';
@import 'Mixins';
@import 'Type';
@import 'Buttons';
@import 'Layout';


.cta {
    position: relative;
    &--column {
        .mainContainer {
          flex-direction: column;
        }
    }
    &Image {
        position: relative;
        width: 100%;

        &:before {
            @media (min-width: 720px) {
                width: 100%;
                content: '';
                display: block;
                padding-bottom: 40%;
            } 
        }
        img {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            object-fit: cover;
            object-position: center center;
            max-width: 100%;
            max-height: 100%;
        }
    }
    &Title {
        font-size: 4.2rem;
        font-family: var(--font-condensed-semibold);
        margin-top: -0.35em;
        line-height: math.div(44, 42);
        text-transform: uppercase;
        color: #FFFFFF;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        text-align: center;
        width: 100%;
        text-align: center;
        max-width: 260px;
        padding-bottom: 2.2rem;
        margin-left: auto;
        margin-right: auto;
        br {display: none;}

        @media (min-width: 720px) {
            text-align: left;
            max-width: 80rem;
            padding-bottom: 0;
            br {display: block;}
        }
        @media (min-width: $md) {
            font-size: 7rem;
            line-height: math.div(76, 70);
            
        }
        @media (min-width: $xl) {
            font-size: 9rem;
            line-height: math.div(105, 90);
        }
        
    }
    &Content {
        position: relative;
        z-index: 1000;
        margin-top: -0.75em;
        width: 100%;
        p {
          @extend .font1;
          + .button {
            margin-top: 2.4rem;
          }
        }
    }
    &Actions {
        padding-top: 5.6rem;
        display: flex;
        align-items: center;
        margin-left: -1rem;
        margin-right: -1rem;
        justify-content: center;
        @media (min-width: 720px) {
            padding-top: 4rem;
        }
    }
    &Action {
        padding-left: 1rem;
        padding-right: 1rem;
    }
}

.mainContainer {
    display: flex;
    position: relative;
    z-index: 1000;
    background-color: var(--brand-blue);
    padding-top: 3.2rem;
    padding-bottom: 3.2rem;
    @media (min-width: $smm) {
        padding-top: 4.4rem;
        padding-bottom: 4.4rem;
    }
    @media (min-width: 720px) {
        padding-top: 0;
        padding-bottom: 0;
        background-color: rgba(0, 0, 0, 0);
    }
}

.col6 {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    color: white;
    flex: 0 0 100%;
        max-width: 100%;
    @media (min-width: 720px) {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        padding-left: 4.8rem;
    }
}

.section {
    &--light {
        padding-top: 6rem;
        padding-bottom: 6rem;
        @media (min-width: $md) {
        padding-top: 8rem;
        padding-bottom: 8rem;
        }
        @media (min-width: $xl) {
        padding-top: 10rem;
        padding-bottom: 10rem;
        }
        @media (min-width: $xxl) {
        padding-top: 12rem;
        padding-bottom: 12rem;
        }
    }
}

.mobileImage {
    display: block;
    max-width: calc(100% + 4rem);
    width: calc(100% + 4rem);
    margin-left: -2rem;
    margin-right: -2rem;
    @media (min-width: 720px) {
        display: none;
    }
}

.desktopImage {
    display: none;
    max-width: 100%;
    width: 100%;
    @media (min-width: 720px) {
        display: block;
    }
}